<template>
  <div class="detail-container">
    <site-slogan />
    <div class="detail-wrapper">
      <tip-ete :title="title" />
      <div class="agenda-section">
        <div v-if="AgendaSubject" class="agenda-title">
          {{ `${translateTitle('主会场头')}${AgendaSubject.Title || ''}` }}
        </div>
        <div v-if="AgendaSubject" class="agenda-subtitle">
          {{ `${translateTitle('日期头')}${AgendaSubject.Abstract || ''}` }}
        </div>
        <agenda-list :list="agendaList" />
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { translateTitle } from '@/utils/i18n'
  import {
    // _GetArticleListByType,

    GetFinalAgendaList,
    _GetArticleListBySID,
  } from '@/utils/deal'
  import { getMenuArticle } from '@/api/site'
  export default {
    name: 'EventAct',
    data() {
      return {
        agendaList: [],
        agendaKey: 'dZ',
        AgendaSubject: '',
        title: '主要活动流程',
      }
    },
    computed: {
      ...mapGetters({
        homeTopUrl: 'settings/homeTopUrl',
        ossBaseUrl: 'settings/ossBaseUrl',
        queryKeys: 'settings/queryKeys',
        videoUrl: 'settings/videoUrl',
      }),
    },
    async created() {
      this.setKeys()
      await this.getArticle()
      await this.getAgendaList()
    },
    async activated() {
      this.setKeys()
    },
    methods: {
      translateTitle,
      setKeys() {
        this.agendaKey = this.queryKeys.agenda_EventAct_key
      },
      setAgendaList(list) {
        if (list) {
          this.agendaList = GetFinalAgendaList(list)
        }
      },
      async getArticle() {
        var res = await getMenuArticle({ SID: this.agendaKey })
        if (res.data) {
          this.AgendaSubject = res.data
        }
      },
      async getAgendaList() {
        if (this.agendaKey) {
          await _GetArticleListBySID(this.agendaKey, (list) => {
            // console.log(list)
            // const menuArtList = list.filter((f) => f.ContentType == 'MenuArticle')
            // console.log('getAgendaList-menuarticle', menuArtList)
            // if (menuArtList) this.AgendaSubject = menuArtList[0]
            this.setAgendaList(list)
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .agenda-section {
    margin-top: 20px;
    border: 0px;
    padding: 20px;
    .agenda-title {
      font-size: 20px;
      margin-top: 5px;
    }
    .agenda-subtitle {
      font-size: 18px;
      margin-top: 5px;
    }
  }
</style>
