<template>
  <div class="detail-container">
    <site-slogan />
    <div class="detail-wrapper">
      <site-article :query-key="queryKey" :title="'酒店介绍'" />
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Accommodation',
    data() {
      return {
        queryKey: '17',
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
    },
    created() {
      this.setKeys()
    },
    activated() {
      this.setKeys()
    },
    methods: {
      setKeys() {
        this.queryKey = this.queryKeys.EventSvices_Accommodation_key
      },
    },
  }
</script>
<style>
  .newtext {
    letter-spacing: 3px;
  }
</style>
