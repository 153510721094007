<template>
  <div class="content-container">
    <site-slogan />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <new-list
        v-if="list.length"
        :list="list"
        :show-more="showMore"
        :stype="newskey"
        @articleClick="articleClick"
        @moreEvent="moreEvent"
      />
      <div v-if="!showMore" class="nomore">
        {{ translateTitle('没有更多了') }}
      </div>
    </div>
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  import { _GetArticlePageListBySID } from '@/utils/deal'
  import { mapGetters } from 'vuex'
  export default {
    name: 'News',
    data() {
      return {
        showMore: true,
        list: [],
        title: '泰国中华总商会新闻',
        newskey: 'aW',
        pageSize: 12,
        currentPageIndex: 1,
        isComplete: false,
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
    },
    async created() {
      this.setKeys()
      await this.getlist()
    },
    async activated() {
      this.setKeys()
      await this.getlist()
      // await this.getlist()newCenter_newlist_key
    },
    methods: {
      setKeys() {
        this.newskey = this.queryKeys.newCenter_newlist_key
      },
      translateTitle,
      async getlist() {
        await this.querylist(1, this.pageSize)
      },
      async querylist(pageIndex, pageSize) {
        await _GetArticlePageListBySID(
          this.newskey,
          pageIndex,
          pageSize,
          (data) => {
            console.log(data)
            if (data) {
              this.list = [...this.list, ...data.list]
              if (data.total == this.list.length) {
                this.isComplete = true
                this.showMore = false
              }
            }
          }
        )
      },
      async moreEvent(e) {
        if (this.isComplete) return
        this.currentPageIndex++
        console.log('moreEvent', e)
        await this.querylist(this.currentPageIndex, this.pageSize)
      },
      articleClick(e) {
        console.log('articleClick', e)
        if (e.OutLink) {
          window.open(e.OutLink)
        } else {
          this.$router.push({
            path: '/detail',
            query: {
              id: e.ArticleID,
              title: e.Title,
            },
          })
        }
      },
    },
  }
</script>
<style lang="scss"></style>
