<template>
  <div class="content-container">
    <item-tip />
    <div class="content-wrapper agenda-section">
      <tip-ete :icon="2" :title="'峰会议程'" />
      <!-- <template v-if="agendaList && agendaList.length">
        <agenda-list :list="agendaList" />
      </template>
      <template v-else>
        <div class="agenda-nodata center">敬请期待</div>
      </template> -->
      <el-tabs
        v-model="agendaTabActiveSID"
        class="agenda-tabs"
        @tab-click="handleTabClick"
      >
        <el-tab-pane
          v-for="(item, index) in agendaTabItems"
          :key="index"
          class="agenda-tabitem"
          :label="item.SName"
          :name="item.SID"
        >
          <template v-if="agendaList && agendaList.length">
            <agenda-list :list="agendaList" />
          </template>
          <template v-else>
            <div class="agenda-nodata center">敬请期待</div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import { _GetNextArticleListBySID, GetFinalAgendaList } from '@/utils/deal'
  import { getMenuTreeByType } from '@/api/site'
  export default {
    name: 'Agenda',
    data() {
      return {
        showMore: false,
        agendaList: [],
        agendaKey: 'YCLBLIST',
        agendaTabItems: [],
        agendaTabActiveSID: '',
      }
    },
    async created() {
      // await this.getAgendaList()
      await this.getAgendaMenu()
    },
    methods: {
      async getAgendaMenu() {
        const res = await getMenuTreeByType({ SType: this.agendaKey })
        if (res && res.data) {
          const menus = res.data.length > 0 ? res.data[0].Children : null
          if (menus) {
            const tabitems = []
            let firstsid = ''
            menus.forEach((item, index) => {
              console.log(index)
              if (index == 0) firstsid = item.SID
              tabitems.push({ SName: item.SName, SID: item.SID })
            })
            this.agendaTabItems = tabitems
            this.agendaTabActiveSID = firstsid
            if (this.agendaTabActiveSID) {
              this.handleTabClick()
            }
          }
        }
      },
      async handleTabClick() {
        if (this.agendaTabActiveSID) {
          await _GetNextArticleListBySID(this.agendaTabActiveSID, (list) => {
            this.setAgendaList(list)
          })
        }
      },
      setAgendaList(list) {
        if (list) {
          this.agendaList = GetFinalAgendaList(list)
        }
      },
      async getAgendaList() {
        // await _GetArticleListByType(this.agendaKey, (list) => {
        //   console.log(list)
        //   if (list) {
        //     let sortList = DealAgendaData(list)
        //     console.log(sortList)
        //     // sortList.splice(1, 0, ...sortList.splice(2, 1))
        //     let newGroupList = []
        //     let totalCount = sortList.length
        //     let totalIndex = 0
        //     if (totalCount > 0) {
        //       if (totalCount <= 2) totalIndex = 1
        //       else if (totalCount / 2 == 0) totalIndex = totalCount / 2
        //       else totalIndex = totalCount / 2 + 1
        //     }
        //     for (let i = 1; i <= totalIndex; i++) {
        //       newGroupList.push({ rowid: i, list: sortList.splice(0, 2) })
        //     }
        //     console.log('newGroupList', newGroupList)
        //     newGroupList.forEach((o1) => {
        //       o1.list.forEach((o2) => {
        //         let tempList = []
        //         o2.list.forEach((o3) => {
        //           let exist = tempList.find(
        //             (i) =>
        //               i.ReleaseTimeText == o3.ReleaseTimeText &&
        //               o3.ReleaseTimeText
        //           )
        //           if (exist) {
        //             exist.list.push(o3)
        //           } else {
        //             tempList.push({
        //               ReleaseTimeText: o3.ReleaseTimeText,
        //               list: [o3],
        //             })
        //           }
        //         })
        //         console.log('tempList', tempList)
        //         o2['newlist'] = tempList
        //       })
        //     })
        //     this.agendaList = newGroupList
        //     console.log('this.agendaList', this.agendaList)
        //   }
        // })
      },
    },
  }
</script>
<style scoped lang="scss">
  .agenda-section {
    margin-bottom: 10px;
  }
</style>
