<template>
  <div class="content-container">
    <item-tip />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <el-row class="file-list" :gutter="20">
        <el-col
          v-for="(item, index) in list"
          :key="index"
          class="file-item-wrapper"
          :md="12"
          :xs="24"
        >
          <div class="file-item text-line">
            <img :src="fileicon" />
            <span class="txt">
              <a
                :href="item.Href"
                :target="item.Href == '#' ? '_self' : '_blank'"
              >
                {{ item.Title }}
              </a>
            </span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Files',
    data() {
      return {
        showMore: false,
        agendaList: [],
        title: '展会文件',
        list: [
          {
            Title: '2022第三届会展三新展邀请函',
            Href: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/2022%E4%BC%9A%E5%B1%95%E4%B8%89%E6%96%B0%E5%B1%95%E9%82%80%E8%AF%B7%E5%87%BD.pdf',
          },
          {
            Title: '2022第三届会展三新展招展函',
            Href: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/2022%E7%AC%AC%E4%B8%89%E5%B1%8A%E4%BC%9A%E5%B1%95%E4%B8%89%E6%96%B0%E5%B1%95%E6%8B%9B%E5%B1%95%E5%87%BD.pdf',
          },
          {
            Title: '2022第三届会展三新展暨峰会日程',
            Href: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/2022%E7%AC%AC%E4%B8%89%E5%B1%8A%E4%BC%9A%E5%B1%95%E4%B8%89%E6%96%B0%E5%B1%95%E6%9A%A8%E5%B3%B0%E4%BC%9A%E6%97%A5%E7%A8%8B.pdf',
          },
          {
            Title: '参展申请表',
            Href: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/2022%E4%BC%9A%E5%B1%95%E4%B8%89%E6%96%B0%E5%B1%95%E5%8F%82%E5%B1%95%E7%94%B3%E8%AF%B7%E8%A1%A8.docx',
          },
          {
            Title: '参会申请表',
            Href: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/2022%E4%BC%9A%E5%B1%95%E4%B8%89%E6%96%B0%E5%B1%95%E5%8F%82%E4%BC%9A%E7%94%B3%E8%AF%B7%E8%A1%A8.docx',
          },
          {
            Title: '2022会展三新展市场推广一览表',
            Href: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/2022%E7%AC%AC%E4%B8%89%E5%B1%8A%E4%BC%9A%E5%B1%95%E4%B8%89%E6%96%B0%E5%B1%95%E6%8E%A8%E5%B9%BF%E4%B8%80%E8%A7%88.pdf',
          },
          {
            Title: '2021第二届会展三新展展后报告',
            Href: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/2021%E7%AC%AC%E4%BA%8C%E5%B1%8A%E4%BC%9A%E5%B1%95%E4%B8%89%E6%96%B0%E5%B1%95%E5%B1%95%E5%90%8E%E6%8A%A5%E5%91%8A.pdf',
          },
          {
            Title: '2020第一届会展三新展展后报告',
            Href: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/2020%E7%AC%AC%E4%B8%80%E5%B1%8A%E4%BC%9A%E5%B1%95%E4%B8%89%E6%96%B0%E5%B1%95%E5%B1%95%E5%90%8E%E6%8A%A5%E5%91%8A.pdf',
          },
        ],
        fileicon:
          'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/fileicon.png',
      }
    },
    async created() {},
    methods: {
      async getList() {},
    },
  }
</script>
<style lang="scss">
  .file-list {
    margin: 20px auto;
    .file-item-wrapper {
      // border: 1px solid #000;
      padding: 10px;
      .file-item {
        background: #fff;
        padding: 15px 20px;
        text-align: left;
        // margin-right: $font30;
        // margin-top: $font30;
        > img {
          width: $px37;
          vertical-align: bottom;
        }
        .txt {
          margin-left: $font20;
          a {
            font-size: $font20;
            color: $base-color-font;
          }
        }
      }
    }
  }
</style>
