<template>
  <div class="tip-x" :style="{ 'padding-left': padding + 'px' }">
    <div class="left">
      <img class="image img" :src="icon == 1 ? imageUrl : imageUrl2" />
      <div class="title" :class="{ 'title-white': icon == 2 }">
        {{ translateTitle(title) }}
      </div>
      <div v-if="subtitle" class="subtitle">{{ translateTitle(subtitle) }}</div>
      <div v-if="date" class="date">{{ translateTitle(date) }}</div>
    </div>
    <div v-show="more" class="newmore" @click="moreEvent()">
      {{ translateTitle('更多') }}
    </div>
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  export default {
    name: 'TipEte',
    props: {
      imageUrl: {
        type: String,
        default:
          'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/tipicon.png',
      },
      padding: {
        type: String,
        default: '0',
      },
      icon: {
        type: Number,
        default: 1,
      },
      title: {
        type: String,
        default: '',
      },
      subtitle: {
        type: String,
        default: '',
      },
      date: {
        type: String,
        default: '',
      },
      more: {
        type: Boolean,
        default: false,
      },
      keyword: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        imageUrl2:
          'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/tipicon_white.png',
      }
    },
    methods: {
      translateTitle,
      moreEvent() {
        if (!this.keyword) return
        this.$emit('moreEvent', {
          keyword: this.keyword,
        })
      },
    },
  }
</script>

<style lang="scss">
  .newmore {
    border-radius: 6px;
    border: 1px solid #999999;
    text-align: center;
    color: #999999;
    padding: 5px 15px;
    cursor: pointer;
  }
  .tip-x {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .title {
      font-size: $font35;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $base-color-golden;
      margin-left: 10px;
    }
    .subtitle,
    .date {
      font-size: $font15;
      margin-left: 20px;
    }
    .title-white {
      color: #fff !important;
    }
    .image {
      width: $px37;
      // width: 37px;
      // height: 40px;
      // border: 1px solid #c78a29;
    }
  }
</style>
