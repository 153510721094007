import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/common/layout'
import {
  publicPath,
  routerMode,
  exhcenterUrl,
  registerUrl,
  previousUrl,
  thconfUrl,
  EventService_VenueIntroUrl,
} from '@/config'
// import { filterRoutes } from '@/utils/routes'
Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    meta: {
      title: '首页',
      levelHidden: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页',
        },
      },
      {
        path: 'detail',
        name: 'Detail',
        component: () => import('@/views/detail'),
        meta: {
          hidden: true,
          title: '文章明细',
        },
      },
      {
        path: 'menuDetail',
        name: 'MenuDetail',
        component: () => import('@/views/menu'),
        meta: {
          hidden: true,
          title: '菜单文章明细',
        },
      },
      // {
      //   path: 'news',
      //   name: 'News',
      //   component: () => import('@/views/news'),
      //   meta: {
      //     title: '新闻中心',
      //   },
      // },
      // {
      //   path: 'agenda',
      //   name: 'Agenda',
      //   component: () => import('@/views/agenda'),
      //   meta: {
      //     title: '峰会议程',
      //   },
      // },
      // {
      //   path: 'electact',
      //   name: 'Electact',
      //   component: () => import('@/views/electact'),
      //   meta: {
      //     title: '推选活动',
      //   },
      // },
      // {
      //   path: 'files',
      //   name: 'Files',
      //   component: () => import('@/views/files'),
      //   meta: {
      //     title: '展会文件',
      //   },
      // },
      // {
      //   path: 'previous',
      //   name: 'Previous',
      //   component: () => import('@/views/previous'),
      //   meta: {
      //     title: '往届回顾',
      //     activeMenu: '/previous',
      //   },
      // },
      // {
      //   path: 'service',
      //   name: 'Service',
      //   component: () => import('@/views/service'),
      //   meta: {
      //     title: '展会服务',
      //   },
      // },
      // {
      //   path: 'contactus',
      //   name: 'Contactus',
      //   component: () => import('@/views/contactus'),
      //   meta: {
      //     title: '联系我们',
      //     activeMenu: '/contactus',
      //   },
      // },
      // {
      //   path: exhcenterUrl,
      //   name: 'Exhcenter',
      //   meta: {
      //     title: '展商中心',
      //     target: '_blank',
      //   },
      // },
      // {
      //   path: visiterUrl,
      //   name: 'Visitcenter',
      //   meta: {
      //     title: '观众中心',
      //     target: '_blank',
      //   },
      // },
    ],
  },
  {
    path: 'entrConf',
    name: 'EntrConf',
    component: Layout,
    meta: {
      title: '世界华商大会',
      // activeMenu: '/entrConf',
    },
    children: [
      {
        path: 'confintro',
        name: 'Confintro',
        component: () => import('@/views/entrConf/confintro'),
        meta: {
          title: '大会介绍',
          // activeMenu: '/confintro',
        },
      },
      {
        path: 'organization',
        name: 'Organization',
        component: () => import('@/views/entrConf/organization'),
        meta: {
          title: '召集人组织',
          // activeMenu: '/organization',
        },
      },
      {
        path: 'advcommittee',
        name: 'Advcommittee',
        component: () => import('@/views/entrConf/advcommittee'),
        meta: {
          title: '顾问委员会',
          // activeMenu: '/advcommittee',
        },
      },
      {
        path: previousUrl,
        name: 'Previous',
        meta: {
          title: '往届回顾',
          target: '_blank',
        },
      },
    ],
  },
  {
    path: 'entrConf16',
    name: 'EntrConf16',
    component: Layout,
    meta: {
      title: '第十六届华商大会',
      // activeMenu: '/entrConf',
    },
    children: [
      {
        path: 'Orgcommittee16',
        name: 'Orgcommittee16',
        component: () => import('@/views/entrConf16/OrgCommittee16'),
        meta: {
          title: '十六届组织委员会',
          // activeMenu: '/confintro',
        },
      },
      {
        path: 'WorkCommittee16',
        name: 'WorkCommittee16',
        component: () => import('@/views/entrConf16/WorkCommittee16'),
        meta: {
          title: '十六届工作委员会',
          // activeMenu: '/organization',
          disabled: true,
        },
      },
      {
        path: thconfUrl,
        name: 'ThaiCCOC',
        // component: () => import('@/views/entrConf16/ThaiCCOC'),
        meta: {
          title: '泰国中华总商会',
          // activeMenu: '/advcommittee',
          target: '_blank',
        },
      },
      {
        path: 'Sponsors',
        name: 'Sponsors',
        component: () => import('@/views/entrConf16/Sponsors'),
        meta: {
          title: '大会赞助企业',
        },
      },
      {
        path: 'Charity',
        name: 'Charity',
        component: () => import('@/views/entrConf16/Charity'),
        meta: {
          title: '慈善公益',
          disabled: true,
        },
      },
      // {
      //   path: 'VirtualShowroom',
      //   name: 'VirtualShowroom',
      //   component: () => import('@/views/entrConf16/VirtualShowroom'),
      //   meta: {
      //     title: '线上展厅',
      //   },
      // },
    ],
  },
  {
    path: 'agenda',
    name: 'Agenda',
    component: Layout,
    meta: {
      title: '大会日程',
    },
    children: [
      {
        path: 'EventInfo',
        name: 'EventInfo',
        component: () => import('@/views/agenda/EventInfo'),
        meta: {
          title: '大会资讯',
        },
      },
      {
        path: 'ExhibitionInfo',
        name: 'ExhibitionInfo',
        component: () => import('@/views/agenda/ExhibitionInfo'),
        meta: {
          title: '展览资讯',
          disabled: true,
        },
      },
      {
        path: 'EventAct',
        name: 'EventAct',
        component: () => import('@/views/agenda/EventAct'),
        meta: {
          title: '主要活动流程',
          disabled: true,
        },
      },
    ],
  },
  {
    path: 'EventSvices',
    name: 'EventSvices',
    component: Layout,
    meta: {
      title: '大会服务',
    },
    children: [
      {
        path: EventService_VenueIntroUrl,
        name: 'VenueIntro',
        // component: () => import('@/views/EventSvices/VenueIntro'),
        meta: {
          title: '会场介绍',
          target: '_blank',
        },
      },
      {
        path: 'Transportation',
        name: 'Transportation',
        component: () => import('@/views/EventSvices/Transportation'),
        meta: {
          title: '交通介绍',
          disabled: true,
        },
      },
      {
        path: 'Accommodation',
        name: 'Accommodation',
        component: () => import('@/views/EventSvices/Accommodation'),
        meta: {
          title: '酒店介绍',
        },
      },
      {
        path: 'FoodBeverages',
        name: 'FoodBeverages',
        component: () => import('@/views/EventSvices/FoodBeverages'),
        meta: {
          title: '餐饮介绍',
        },
      },
      {
        path: 'TravelTips',
        name: 'TravelTips',
        component: () => import('@/views/EventSvices/TravelTips'),
        meta: {
          title: '旅游介绍',
        },
      },
      {
        path: 'BusinessTours',
        name: 'BusinessTours',
        component: () => import('@/views/EventSvices/BusinessTours'),
        meta: {
          title: '商务考察',
        },
      },
      {
        path: 'GolfMatch',
        name: 'GolfMatch',
        component: () => import('@/views/EventSvices/GolfMatch'),
        meta: {
          title: '高尔夫友谊赛',
        },
      },
      {
        path: 'Documents',
        name: 'Documents',
        component: () => import('@/views/EventSvices/Documents'),
        meta: {
          title: '文档下载',
        },
      },
    ],
  },
  {
    path: 'newCenter',
    name: 'NewCenter',
    component: Layout,
    meta: {
      title: '新闻中心',
    },
    children: [
      {
        path: 'newlist',
        name: 'Newlist',
        component: () => import('@/views/newCenter/newlist'),
        meta: {
          title: '泰国中华总商会新闻',
        },
      },
      {
        path: 'wnews',
        name: 'Wnews',
        component: () => import('@/views/newCenter/wnews'),
        meta: {
          title: '第十六届世界华商大会新闻',
        },
      },
      {
        path: 'atlas',
        name: 'Atlas',
        meta: {
          title: '图文集',
        },
        children: [
          {
            path: 'newimages',
            name: 'Newimages',
            component: () => import('@/views/newCenter/atlas/newimages'),
            meta: {
              title: '新闻图片',
              disabled: true,
            },
          },
          {
            path: 'newvideos',
            name: 'Newvideos',
            component: () => import('@/views/newCenter/atlas/newvideos'),
            meta: {
              title: '新闻视频',
            },
          },
          {
            path: exhcenterUrl,
            name: 'Conflive',
            meta: {
              title: '大会直播',
              target: '_blank',
              disabled: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'Invest',
    name: 'Invest',
    component: Layout,
    meta: {
      title: '投资泰国',
      levelHidden: true,
    },
    children: [
      {
        path: 'InvestTh',
        name: 'InvestTh',
        component: () => import('@/views/InvestTh'),
        meta: {
          title: '投资泰国',
        },
      },
    ],
  },
  {
    path: 'contact',
    name: 'Contact',
    component: Layout,
    meta: {
      title: '联系我们',
      levelHidden: true,
    },
    children: [
      {
        path: 'contactus',
        name: 'Contactus',
        component: () => import('@/views/contactus'),
        meta: {
          title: '联系我们',
        },
      },
    ],
  },
  {
    path: 'congratulate',
    name: 'congratulate',
    component: Layout,
    meta: {
      title: '大会祝贺函',
      levelHidden: true,
    },
    children: [
      {
        path: 'congletter',
        name: 'Congletter',
        component: () => import('@/views/congletter'),
        meta: {
          title: '大会祝贺函',
        },
      },
    ],
  },
  // {
  //   path: exhcenterUrl,
  //   name: 'Exhcenter',
  //   meta: {
  //     title: '展商展示',
  //     target: '_blank',
  //     disabled: true,
  //   },
  // },
  {
    path: registerUrl,
    name: 'register',
    meta: {
      title: '嘉宾注册',
      target: '_blank',
      class: 'btn-reg-wcec',
      expirat: true,
    },
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter()

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
  // console.log('createRouter', routes)
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
