<template>
  <div class="detail-container">
    <site-slogan />
    <div class="detail-wrapper">
      <tip-ete :title="title" />
      <el-row :gutter="20">
        <el-col
          v-for="(item, index) in list"
          :key="index"
          class="image-list-wrapper"
          :md="8"
          :sm="12"
          :xs="24"
        >
          <div class="image-list-item">
            <el-image :preview-src-list="item.srcList" :src="item.url" />
          </div>
        </el-col>
      </el-row>
    </div>
    <site-org ref="dialogRef" :title="'个人简介'" />
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  import { mapGetters } from 'vuex'
  import { _GetArticleListByType } from '@/utils/deal'
  export default {
    name: 'Congletter',
    data() {
      return {
        showMore: true,
        list: [],
        title: '大会祝贺函',
        queryKey: 'congletterkey',
        pageSize: 12,
        currentPageIndex: 1,
        isComplete: false,
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
    },
    async created() {
      this.setKeys()
      await this.getlist()
    },
    async activated() {
      this.setKeys()
    },
    methods: {
      setKeys() {
        this.queryKey = this.queryKeys.congletterkey
      },
      translateTitle,
      async getlist() {
        await this.querylist()
      },
      async querylist() {
        await _GetArticleListByType(this.queryKey, (data) => {
          if (data) {
            var templist = data.map((item) => {
              if (item.OssImagePath && item.LogoPath) {
                var logopaths = item.LogoPath.split(']')
                if (logopaths.length) {
                  var srclist = []
                  logopaths.forEach((ele) => {
                    console.log(ele)
                    srclist.push(item.OssImagePath + ele)
                  })
                  item['srcList'] = srclist
                  item['url'] = srclist[0]
                }
              }

              return item
            })
            console.log(templist)
            this.list = templist
          }
        })
      },
      // async moreEvent(e) {
      //   if (this.isComplete) return
      //   this.currentPageIndex++
      //   console.log('moreEvent', e)
      //   await this.querylist(this.currentPageIndex, this.pageSize)
      // },
      articleClick(e) {
        this.$refs.dialogRef.showDialog(e)
        // console.log('articleClick', e)
        // if (e.OutLink) {
        //   window.open(e.OutLink)
        // } else {
        //   this.$router.push({
        //     path: '/detail',
        //     query: {
        //       id: e.ArticleID,
        //       title: e.Title,
        //     },
        //   })
        // }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .detail-wrapper {
    .image-list-wrapper {
      margin-top: 20px;
      border-radius: 8px;
      padding: 0;
      .image-list-item {
        border: 1px solid #c78a29;
        padding: 10px;
        border-radius: 5px;
        background: #fff;
        // height: 12rem;
        height: 40rem;
        overflow: hidden;

        > img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }
  .org-list {
    .org-list-tip {
      color: #c78a29;
      font-size: $font30;
      font-weight: 600;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
    .org-list-item-container {
      border-radius: 8px;
      padding: 10px;
      &.org-one {
        max-width: 400px;
      }
      // margin-top: 20px;
      .org-list-item {
        background: #fff;
        border: 1px solid #c78a29;
        border-radius: 5px;
        .org-list-item-img {
          img {
            width: 100%;
            border-radius: 5px 5px 0px 0px;
          }
        }
        .org-list-item-title {
          text-align: center;
          font-size: $font15;
          padding: 12px 0px;
          &.ch {
            font-size: $font15;
          }
        }
      }
    }
  }
</style>
