<template>
  <div class="swiper-contianer-multi-home">
    <div class="swiper-wrapper">
      <template v-for="(item, index) in swiperList">
        <div
          :key="index"
          class="swiper-slide"
          @click.stop="swiperItemClick(item)"
        >
          <div class="img-item">
            <template v-if="!showt">
              <img
                v-if="item.src || item.ImageUrl"
                :src="item.src || item.ImageUrl"
              />
            </template>
            <template v-else>
              <img
                v-if="item.src || item.ImageUrl"
                :src="(item.src || item.ImageUrl) + '?t=' + t"
              />
            </template>
          </div>
        </div>
      </template>
    </div>
    <!-- <div class="swiper-pagination-multi"></div> -->
  </div>
</template>

<script>
  import Swiper from 'swiper'
  import 'swiper/dist/css/swiper.min.css'

  export default {
    name: 'SwiperEteMultiHome',
    props: {
      swiperList: {
        type: Array,
        default() {
          return []
        },
      },
      showt: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {}
    },
    computed: {
      t() {
        return new Date().getTime()
      },
    },
    // watch: {
    //   swiperList: function () {
    //     let $this = this
    //     this.$nextTick(() => {
    //       $this.initSwiper()
    //     })
    //   },
    // },
    mounted() {
      let $this = this
      this.$nextTick(() => {
        $this.initSwiper()
      })
    },
    methods: {
      swiperItemClick(item) {
        console.log('swiperItemClick', item)
        if (item.Href && item.Href != '#') {
          window.open(item.Href)
        }
      },
      initSwiper() {
        new Swiper('.swiper-contianer-multi-home', {
          slidesPerView: 2,
          slidesPerGroup: 1,
          slidesPerColumn: 1,
          spaceBetween: 0,
          // autoplay: 10000,
          grabCursor: true,
          observer: true,
          observeParents: true,
          pagination: '.swiper-pagination-multi-home',
          breakpoints: {
            // 480: {
            //   slidesPerView: 2,
            //   slidesPerGroup: 1,
            //   spaceBetween: 10,
            // },
            // 768: {
            //   slidesPerView: 3,
            //   slidesPerGroup: 1,
            //   spaceBetween: 20,
            // },
            1299: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            1300: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
          },
        })
      },
    },
  }
</script>

<style lang="scss">
  .swiper-contianer-multi-home {
    position: relative;
    --swiper-theme-color: $base-color-golden;
    --swiper-pagination-color: $base-color-golden;
    width: 100%;
    .swiper-slide {
      // border: 1px solid #999999;
      // border-radius: 8px;
      .img-item {
        // padding: 20px;
        // width: 150px;
        // max-height: 600px;
        margin: 0 auto;
        // display: flex;
        // align-items: center;
        > img {
          width: 100%;
          object-fit: contain;
          display: block;
        }
      }
    }
    .swiper-wrapper {
      // padding-bottom: 20px;
      align-items: flex-end;
    }
  }
</style>
