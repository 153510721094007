/**
 * @description tabsBar标签页逻辑，如无必要请勿修改
 */
const state = () => ({
  visitedRoutes: [],
})
const getters = {
  visitedRoutes: (state) => state.visitedRoutes,
}
const mutations = {
  /**
   * @description 添加标签页
   * @param {*} state
   * @param {*} route
   * @returns
   */
  addVisitedRoute(state, route) {
    const target = state.visitedRoutes.find((item) => item.path === route.path)
    if (target && !route.meta.dynamicNewTab) Object.assign(target, route)
    else if (!target) state.visitedRoutes.push(Object.assign({}, route))
  },
}
const actions = {
  /**
   * @description 添加标签页
   * @param {*} { commit }
   * @param {*} route
   */
  addVisitedRoute({ commit }, route) {
    commit('addVisitedRoute', route)
  },
}
export default { state, getters, mutations, actions }
