<template>
  <div v-if="!itemTipUrl" class="item-tip">
    <img :src="itemTipUrl" />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'ItemTip',
    props: {
      title: {
        type: String,
        default: '',
      },
      imageUrl: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters({
        itemTipUrl: 'settings/itemTipUrl',
      }),
    },
  }
</script>

<style lang="scss">
  .item-tip {
    // padding: 30px 0px;
    text-align: center;
    .title {
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: $base-color-red;
    }
    > img {
      width: 100%;
    }
  }
</style>
