<template>
  <div class="content-container">
    <item-tip />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <el-row class="file-list" :gutter="20">
        <el-col
          v-for="(item, index) in list"
          :key="index"
          class="file-item-wrapper"
          :md="12"
          :xs="24"
        >
          <div class="file-item text-line">
            <img :src="fileicon" />
            <span class="txt">
              <a :href="item.FileUrl" :target="'_blank'">
                {{ item.Title }}
              </a>
            </span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { _GetTagFiles } from '@/utils/deal'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Documents',
    data() {
      return {
        showMore: false,
        agendaList: [],
        title: '文档下载',
        list: [],
        fileicon:
          'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/fileicon.png',
        queryKey: 'Docments',
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
    },
    async created() {
      this.setKeys()
      await this.getlist()
    },
    async activated() {
      this.setKeys()
    },
    methods: {
      setKeys() {
        this.queryKey = this.queryKeys.EventSvices_Documents_key
      },
      async getlist() {
        await this.querylist()
      },
      async querylist() {
        await _GetTagFiles(this.queryKey, (list) => {
          // let imageArray = []
          // if (list) {
          //   console.log('querylist', list)
          //   list.forEach((item) => {
          //     if (item.ImageUrl) imageArray.push({ Title: item.ImageUrl })
          //   })
          // }
          this.list = list
        })
      },
    },
  }
</script>
<style lang="scss">
  .file-list {
    margin: 20px auto;
    .file-item-wrapper {
      // border: 1px solid #000;
      padding: 10px;
      .file-item {
        background: #fff;
        padding: 15px 20px;
        text-align: left;
        // margin-right: $font30;
        // margin-top: $font30;
        > img {
          width: $px37;
          vertical-align: bottom;
        }
        .txt {
          margin-left: $font20;
          a {
            font-size: $font20;
            color: $base-color-font;
          }
        }
      }
    }
  }
</style>
