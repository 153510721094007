<template>
  <div class="swiper-contianer-multi">
    <div class="swiper-wrapper">
      <template v-for="(item, index) in swiperList">
        <div
          :key="index"
          class="swiper-slide"
          @click.stop="swiperItemClick(item)"
        >
          <div class="img-item">
            <template v-if="!showt">
              <img
                v-if="item.src || item.ImageUrl"
                :src="item.src || item.ImageUrl"
              />
            </template>
            <template v-else>
              <img
                v-if="item.src || item.ImageUrl"
                :src="(item.src || item.ImageUrl) + '?t=' + t"
              />
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="swiper-pagination-multi"></div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  import 'swiper/dist/css/swiper.min.css'

  export default {
    name: 'SwiperEteMulti',
    props: {
      swiperList: {
        type: Array,
        default() {
          return []
        },
      },
      showt: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {}
    },
    computed: {
      t() {
        return new Date().getTime()
      },
    },
    // watch: {
    //   swiperList: function () {
    //     let $this = this
    //     this.$nextTick(() => {
    //       $this.initSwiper()
    //     })
    //   },
    // },
    mounted() {
      let $this = this
      this.$nextTick(() => {
        $this.initSwiper()
      })
    },
    methods: {
      swiperItemClick(item) {
        console.log('swiperItemClick', item)
        if (item.Href && item.Href != '#') {
          window.open(item.Href)
        }
      },
      initSwiper() {
        new Swiper('.swiper-contianer-multi', {
          slidesPerView: 5,
          slidesPerGroup: 1,
          slidesPerColumn: 2,
          spaceBetween: 20,
          autoplay: 3000,
          grabCursor: true,
          observer: true,
          observeParents: true,
          pagination: '.swiper-pagination-multi',
          breakpoints: {
            480: {
              slidesPerView: 2,
              slidesPerGroup: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 1,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 4,
              slidesPerGroup: 1,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 5,
              slidesPerGroup: 1,
              spaceBetween: 20,
            },
          },
        })
      },
    },
  }
</script>

<style lang="scss">
  .swiper-contianer-multi {
    position: relative;
    --swiper-theme-color: $base-color-golden;
    --swiper-pagination-color: $base-color-golden;
    width: 100%;
    .swiper-slide {
      border: 1px solid #999999;
      border-radius: 8px;
      .img-item {
        padding: 20px;
        width: 150px;
        height: 150px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        > img {
          width: 100%;
          display: block;
        }
      }
    }
    .swiper-wrapper {
      padding-bottom: 20px;
    }
  }
</style>
