<template>
  <div class="site-app-main">
    <transition mode="out-in" name="fade-transform">
      <!-- <keep-alive :include="keepAliveNameList" :max="keepAliveMaxNum"> -->
      <router-view :key="routerKey" />
      <!-- </keep-alive> -->
    </transition>
    <site-footer />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'SiteAppMain',
    data() {
      return { routerKey: null, keepAliveMaxNum: 20, keepAliveNameList: [] }
    },
    computed: {
      ...mapGetters({
        visitedRoutes: 'tabs/visitedRoutes',
      }),
    },
    watch: {
      $route: {
        handler() {
          // console.log('路由变化 this.$route.fullPath：', this.$route.fullPath)
          this.routerKey = this.$route.fullPath
        },
        immediate: true,
      },
    },
    created() {
      //仅刷新路由
      // this.$baseEventBus.$on('reload-router-view', () => {
      //   this.routerKey = null
      //   this.$nextTick(() => {
      //     this.routerKey = this.$route.path
      //   })
      // })
      // this.updateKeepAliveNameList()
      this.$baseEventBus.$on('reload-router-view', () => {
        // console.log('this.routerKey', this.routerKey)
        this.routerKey = null
        // this.keepAliveNameList = []
        // this.updateKeepAliveNameList(this.$route.name)
        this.$nextTick(() => {
          this.routerKey = this.$route.path
          // this.keepAliveNameList = [this.$route.name]
          // this.updateKeepAliveNameList()
        })
      })
    },
    methods: {
      updateKeepAliveNameList(refreshRouteName = null) {
        var a = [
          ...new Set(
            this.visitedRoutes
              .filter(
                (item) =>
                  !item.meta.noKeepAlive && item.name !== refreshRouteName
              )
              .flatMap((item) => item.matched)
          ),
        ]
        console.log('updateKeepAliveNameList', a)
        this.keepAliveNameList = a
      },
    },
  }
</script>
