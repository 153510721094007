<template>
  <div class="site-circle">
    <template v-if="!type">
      <site-sub-circle :count="count" :total="totalcount" />
    </template>
    <template v-else>
      <div class="item">
        <div class="item">
          <slot></slot>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'SiteCircle',
    props: {
      count: {
        type: Number,
        default: 1,
      },
      type: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        list: [],
        totalcount: 1,
      }
    },
    created() {},
    methods: {},
  }
</script>
<style lang="scss">
  .site-circle {
    margin: 0 auto;
    .item {
      background: #ffffff;
      border-radius: 100%;
      border: 2px solid #c78a29;
      padding: 16px;
      &.first {
        width: 48px;
        height: 48px;
      }
    }
    .btn {
      width: 146px;
      height: 146px;
      background: #ffffff;
      border-radius: 100%;
      border: 2px solid #c78a29;
      color: #c78a29;
      font-weight: 600;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 41px;
        height: 42px;
      }
      .txt {
        margin-top: 5px;
      }
    }
    .btn-reg {
      border: 0px solid #c78a29;
      background: url('https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/icon-edit-red.png')
        no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
  }
</style>
