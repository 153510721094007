<template>
  <div class="site-circle-menu">
    <div class="pos-pc" :class="poshoverClass">
      <div class="pos pos1">
        <!-- <site-circle :count="5" /> -->
        <img :src="ossBaseUrl + btnc5" />
      </div>
      <div class="pos pos2 pos-hover" @click="menuClick(2)">
        <img :src="ossBaseUrl + iconedit" />
        <!-- <div class="c1">
          <div class="c2">
            <div class="btn btn-reg">
              <div><img :src="ossBaseUrl + iconedit" /></div>
              <div class="txt"><span>嘉宾注册</span></div>
            </div>
          </div>
        </div> -->
        <!-- <site-circle :type="type">

      </site-circle> -->
      </div>
      <div class="pos pos3">
        <img :src="ossBaseUrl + btnc3" />
        <!-- <site-circle :count="3" /> -->
      </div>
      <div class="pos pos4 pos-hover" @click="menuClick(4)">
        <img :src="ossBaseUrl + iconexh" />
        <!-- <site-circle :type="type">
        <div class="btn btn-exh">
          <div><img :src="ossBaseUrl + iconexh" /></div>
          <div class="txt"><span>展商展示</span></div>
        </div>
      </site-circle> -->
      </div>
      <div class="pos pos5">
        <!-- <site-circle :count="6" /> -->
        <img :src="ossBaseUrl + btnc6" />
      </div>
      <div class="pos pos6 pos-hover" @click="menuClick(6)">
        <img :src="ossBaseUrl + icondate" />
        <!-- <site-circle :type="type">
        <div class="btn btn-ang">
          <div><img :src="ossBaseUrl + icondate" /></div>
          <div class="txt"><span>大会日程</span></div>
        </div>
      </site-circle> -->
      </div>
      <div class="pos pos7">
        <!-- <site-circle :count="3" /> -->
        <img :src="ossBaseUrl + btnc3" />
      </div>
      <div class="pos pos8 pos-hover" @click="menuClick(8)">
        <img :src="ossBaseUrl + iconfile" />
        <!-- <site-circle :type="type">
        <div class="btn btn-file">
          <div><img :src="ossBaseUrl + iconfile" /></div>
          <div class="txt"><span>文档下载</span></div>
        </div>
      </site-circle> -->
      </div>
    </div>
    <div class="pos-mobile" :class="poshoverClass">
      <div class="pos-mob-list">
        <div class="pos-item pos-hover" @click="menuClick(2)">
          <img :src="ossBaseUrl + iconedit" />
        </div>
        <div class="pos-item pos-hover" @click="menuClick(4)">
          <img :src="ossBaseUrl + iconexh" />
        </div>
        <div class="pos-item pos-hover" @click="menuClick(6)">
          <img :src="ossBaseUrl + icondate" />
        </div>
        <div class="pos-item pos-hover" @click="menuClick(8)">
          <img :src="ossBaseUrl + iconfile" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { translateTitle } from '@/utils/i18n'
  // import { registerUrl, exhcenterUrl, exhApplyUrl } from '@/config'
  // import { registerUrl } from '@/config'
  export default {
    name: 'SiteCircleMenu',
    data() {
      return {
        list: [],
        // iconedit: 'menu/reg.png',
        // iconexh: 'menu/exhreg.png',
        // icondate: 'menu/agenda.png',
        // iconfile: 'menu/file.png',
        btnc5: 'c5.png',
        btnc3: 'c3.png',
        btnc6: 'c6.png',
        btnctest: 'cda.png',
        type: true,
        m2key: '',
        m4key: '',
        m6key: '',
        m8key: '',
      }
    },
    computed: {
      ...mapGetters({
        ossBaseUrl: 'settings/ossBaseUrl',
        lang: 'settings/getLanguage',
        queryKeys: 'settings/queryKeys',
      }),
      iconedit() {
        return this.lang == 'en'
          ? 'menu/regen.png'
          : this.lang == 'th'
          ? 'menu/regth.png'
          : 'menu/reg.png'
        // return 'menu/reg.png'
      },
      iconexh() {
        return this.lang == 'en'
          ? 'menu/exhen.png'
          : this.lang == 'th'
          ? 'menu/exhth1.png'
          : 'menu/congIcon.png'
      },
      icondate() {
        return this.lang == 'en'
          ? 'menu/agendaen.png'
          : this.lang == 'th'
          ? 'menu/agendath.png'
          : 'menu/agenda.png'
      },
      iconfile() {
        return this.lang == 'en'
          ? 'menu/fileen.png'
          : this.lang == 'th'
          ? 'menu/fileth.png'
          : 'menu/file.png'
      },
      poshoverClass() {
        return {
          'pos-zh': this.lang == 'zh',
          'pos-en': this.lang == 'en',
          'pos-th': this.lang == 'th',
        }
      },
    },
    async created() {
      // this.setKeys()
    },
    async activated() {
      // this.setKeys()
    },
    methods: {
      // setKeys() {
      //   const $this = this
      //   if ($this.queryKeys) {
      //     Object.keys($this.queryKeys).forEach((key) => {
      //       $this[key] = $this.queryKeys[key]
      //     })
      //   }
      // },
      translateTitle,
      menuClick(type) {
        switch (type) {
          case 2:
            this.$baseNotify(
              // this.translateTitle('敬请期待'),
              // this.translateTitle('系统提示'),
              '已截止'
              // 'info'
            )
            // window.open(registerUrl)
            break
          case 4:
            // this.$baseNotify(
            //   this.translateTitle('敬请期待'),
            //   this.translateTitle('系统提示'),
            //   'info'
            // )
            // window.open(exhcenterUrl)
            this.$router.push({
              path: 'congratulate/congletter',
            })
            break
          case 6:
            // this.$baseNotify(
            //   this.translateTitle('敬请期待'),
            //   this.translateTitle('系统提示'),
            //   'info'
            // )
            this.$emit('menuClick', {
              menukey: 'm6key',
              title: this.translateTitle('大会日程'),
            })
            // window.open(exhApplyUrl)
            break
          case 8:
            this.$router.push({
              path: 'EventSvices/Documents',
            })
            // this.$baseNotify(
            //   this.translateTitle('敬请期待'),
            //   this.translateTitle('系统提示'),
            //   'info'
            // )
            // window.open(exhApplyUrl)
            break
        }
      },
    },
  }
</script>
<style lang="scss">
  .site-circle-menu {
    margin: 0 auto;
    width: 100%;
    height: calc(80vw * 300px / 1117px - 50px);
    .pos-pc {
      max-width: 1117px;
      width: 80%;
      height: calc(80vw * 300px / 1117px);
      position: relative;
      margin: 0 auto;
      top: -50px;
      .pos {
        position: absolute;
        .c1,
        .c2 {
          background: #ffffff;
          border-radius: 100%;
          border: 2px solid #c78a29;
          padding: 16px;
        }
        img {
          width: 100%;
          object-fit: contain;
        }
        &.pos1 {
          width: 16.15%;
          max-width: 180px;
          top: 0px;
          left: 0px;
          z-index: 50;
        }
        &.pos2 {
          width: 19.193%;
          max-width: 214px;
          top: -18%;
          left: 12%;
          z-index: 51;
          cursor: pointer;
        }
        &.pos3 {
          width: 10.135%;
          max-width: 113px;
          top: 4%;
          left: 27%;
          z-index: 49;
        }
        &.pos4 {
          width: 19.193%;
          max-width: 214px;
          top: 8%;
          left: 33%;
          z-index: 51;
          cursor: pointer;
        }
        &.pos5 {
          width: 19.193%;
          max-width: 214px;
          top: 11%;
          left: 45%;
          z-index: 49;
        }
        &.pos6 {
          width: 19.193%;
          max-width: 214px;
          top: -6%;
          left: 58%;
          z-index: 51;
          cursor: pointer;
        }
        &.pos7 {
          width: 10.135%;
          max-width: 113px;
          top: 4%;
          left: 76%;
          z-index: 49;
        }
        &.pos8 {
          width: 19.193%;
          max-width: 214px;
          top: 4%;
          left: 82.5%;
          z-index: 49;
          cursor: pointer;
        }
      }
    }
    // .pos {
    //   position: absolute;
    //   .c1,
    //   .c2 {
    //     background: #ffffff;
    //     border-radius: 100%;
    //     border: 2px solid #c78a29;
    //     padding: 16px;
    //   }
    //   img {
    //     width: 100%;
    //     object-fit: contain;
    //   }
    //   &.pos1 {
    //     width: 16.15%;
    //     max-width: 180px;
    //     top: 0px;
    //     left: 0px;
    //     z-index: 50;
    //   }
    //   &.pos2 {
    //     width: 19.193%;
    //     max-width: 214px;
    //     top: -15%;
    //     left: 12%;
    //     z-index: 51;
    //   }
    //   &.pos3 {
    //     width: 10.135%;
    //     max-width: 113px;
    //     top: 4%;
    //     left: 27%;
    //     z-index: 49;
    //   }
    //   &.pos4 {
    //     width: 19.193%;
    //     max-width: 214px;
    //     top: 10%;
    //     left: 33%;
    //     z-index: 51;
    //   }
    //   &.pos5 {
    //     width: 19.193%;
    //     max-width: 214px;
    //     top: 12%;
    //     left: 45%;
    //     z-index: 49;
    //   }
    //   &.pos6 {
    //     width: 19.193%;
    //     max-width: 214px;
    //     top: -6%;
    //     left: 58%;
    //     z-index: 51;
    //   }
    //   &.pos7 {
    //     width: 10.135%;
    //     max-width: 113px;
    //     top: 4%;
    //     left: 76%;
    //     z-index: 49;
    //   }
    //   &.pos8 {
    //     width: 19.193%;
    //     max-width: 214px;
    //     top: 4%;
    //     left: 82.5%;
    //     z-index: 49;
    //   }
    // }
    .pos-mobile {
      display: none;
      padding: 20px;
      .pos-mob-list {
        display: flex;
        justify-content: space-between;
        .pos-item {
          width: 23%;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .btn {
      width: 146px;
      height: 146px;
      background: #ffffff;
      border-radius: 100%;
      border: 2px solid #c78a29;
      color: #c78a29;
      font-weight: 600;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 41px;
        height: 42px;
      }
      .txt {
        margin-top: 5px;
      }
    }
    .btn-reg {
      border: 0px solid #c78a29;
      background: url('https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/icon-edit-red.png')
        no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
  }
  @media (min-width: 1117px) {
    .site-circle-menu {
      height: 200px !important;
      .pos-pc {
        height: 280px !important;
      }
    }
  }
  @media (max-width: 900px) {
    .site-circle-menu {
      height: auto !important;
      .pos-pc {
        display: none;
      }
      .pos-mobile {
        display: block;
      }
    }
  }
  @media (min-width: 901px) {
    .site-circle-menu {
      .pos-pc {
        display: block;
      }
      .pos-mobile {
        display: none;
      }
    }
  }
</style>
