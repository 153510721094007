<template>
  <div class="site-article">
    <div class="detail-tip">
      <tip-ete :title="title" />
    </div>
    <div v-if="detail.content" v-html="detail.content"></div>
  </div>
</template>
<script>
  import { getMenuArticle } from '@/api/site'
  import { translateTitle } from '@/utils/i18n'
  export default {
    name: 'SiteArticle',
    props: {
      queryKey: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        detail: {
          content: '',
          ArticleID: '',
          title: '',
          abstract: '',
        },
      }
    },
    async created() {
      await this.getArticle()
    },
    methods: {
      translateTitle,
      async getArticle() {
        var res = await getMenuArticle({ SID: this.queryKey })
        if (res.data) {
          let data = res.data
          this.detail.ArticleID = data.ArticleID
          this.detail.title = data.Title || ''
          this.detail.abstract = data.Abstract || ''
          if (data.Contents) {
            this.detail.content = data.Contents
          }
        }
      },
    },
  }
</script>
<style scoped>
  .site-article {
    /* background: #fff;
    padding: 20px; */
  }
</style>
