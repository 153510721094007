<template>
  <div class="content-container">
    <site-slogan />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <el-row class="org-type-menu" :gutter="20">
        <el-col
          v-for="(item, index) in typeList"
          :key="index"
          class="org-type-item-container"
          :md="4"
          :sm="8"
          :xs="12"
        >
          <div
            class="org-type-menu-item"
            :class="{ active: typeSelected == item.SID }"
            @click="typelistClick(item, index)"
          >
            {{ item.SName }}
          </div>
        </el-col>
      </el-row>

      <div
        v-for="(item, index) in dealList"
        :key="index"
        class="org-list"
        :class="'floor' + index"
      >
        <div v-if="item.list && item.list.length" class="org-list-tip">
          <sub-tip :title="item.item.SName" />
        </div>

        <template v-if="item.list.length == 1">
          <el-row class="" :gutter="10" justify="center" type="flex">
            <el-col
              v-for="(it, idx) in item.list"
              :key="idx"
              class="org-list-item-container org-one"
            >
              <div class="org-list-item">
                <div class="org-list-item-img img-hover" @click="orgClick(it)">
                  <img class="" :src="it.OssImagePath + it.LogoPath" />
                </div>
                <div class="org-list-item-title">
                  <div class="txt-one-line">{{ it.Title }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <el-row class="" :gutter="10">
            <el-col
              v-for="(it, idx) in item.list"
              :key="idx"
              class="org-list-item-container"
              :md="4"
              :sm="6"
              :xs="12"
            >
              <div class="org-list-item">
                <div class="org-list-item-img img-hover" @click="orgClick(it)">
                  <img :src="it.OssImagePath + it.LogoPath" />
                </div>
                <div class="org-list-item-title">
                  <div class="txt-one-line">{{ it.Title }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </template>
        <!-- <el-col
            v-for="(it, idx) in item.list"
            :key="idx"
            class="org-list-item-container"
            :sm="6"
            :xs="12"
          >
            <div class="org-list-item">
              <div class="org-list-item-img img-hover" @click="orgClick(it)">
                <img :src="it.OssImagePath + it.LogoPath" />
              </div>
              <div class="org-list-item-title">
                {{ it.Title }}
              </div>
            </div>
          </el-col> -->
      </div>
    </div>
    <site-org ref="dialogRef" :title="'个人简介'" />
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  import { _GetNextArticleListBySID } from '@/utils/deal'
  import { getMenuListByPId } from '@/api/site'
  import { mapGetters } from 'vuex'
  export default {
    name: 'OrgCommittee16',
    data() {
      return {
        list: [],
        typeList: [],
        title: '十六届组织委员会',
        newskey: 'mk',
        typeSelected: '',
        scrolltop: 0,
        floorObject: {},
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
      dealList() {
        let result = []
        const plist = this.list
        if (this.typeList) {
          this.typeList.forEach((it) => {
            var filterlist = plist.filter((i) => i.SID == it.SID)
            let type = {
              item: { SName: it.SName, SID: it.SID },
              list: filterlist,
            }
            result.push(type)
          })
        }
        // console.log('dealList', result)
        return result
      },
    },
    async created() {
      this.setKeys()
      await this.getSubjects()
      await this.getlist()
    },
    mounted() {
      // this.getFloorDistance()
      document.getElementById('app').addEventListener('scroll', () => {
        this.scrolltop = document.getElementById('app').scrollTop
        // console.log('this.scrolltop', this.scrolltop)
      })
    },
    async activated() {
      this.setKeys()
      this.getFloorDistance()
      // await this.getlist()
    },
    methods: {
      setKeys() {
        this.newskey = this.queryKeys.entrconf16_OrgCommittee16_key
      },
      orgClick(item) {
        // console.log('orgClick', item)
        this.$refs.dialogRef.showDialog(item)
      },
      typelistClick(item, index) {
        // console.log('typelistClick', index)
        this.typeSelected = item.SID
        this.anchors(index)
      },
      getFloorDistance() {
        setTimeout(() => {
          for (let i = 0; i < this.typeList.length; i++) {
            this.floorObject['floor' + i] =
              document.getElementsByClassName('floor' + i)[0].offsetTop - 80
          }
          console.log('getFloorDistance-this.floorObject', this.floorObject)
        }, 500)
      },
      anchors(item) {
        this.pulleyRoll(this.floorObject['floor' + item], this.scrolltop)
      },
      pulleyRoll(top, distance) {
        console.log('pulleyRoll:', distance, top)
        if (distance < top) {
          let smallInterval = (top - distance) / 50
          console.log('pulleyRoll-smallInterval:', smallInterval)
          let i = 0
          let timer = setInterval(() => {
            i++
            distance += smallInterval
            document.getElementById('app').scrollTop = distance
            if (i == 50) {
              clearInterval(timer)
            }
          }, 10)
        } else if (distance > top) {
          let smallInterval = (distance - top) / 50
          let i = 0
          let timer = setInterval(() => {
            i++
            distance -= smallInterval
            document.getElementById('app').scrollTop = distance
            if (i == 50) {
              clearInterval(timer)
            }
          }, 10)
        }
      },
      translateTitle,
      async getSubjects() {
        var res = await getMenuListByPId({ parentID: this.newskey })
        if (res && res.code == 200) {
          // console.log('getSubjects', data)
          this.typeList = res.data
        }
      },
      async getlist() {
        await this.querylist()
      },
      async querylist() {
        await _GetNextArticleListBySID(this.newskey, (data) => {
          // console.log(data)
          if (data) {
            this.list = data
            this.$nextTick(() => {
              this.getFloorDistance()
            })
          }
        })
      },
      articleClick(e) {
        console.log('articleClick', e)
        if (e.OutLink) {
          window.open(e.OutLink)
        } else {
          this.$router.push({
            path: '/detail',
            query: {
              id: e.ArticleID,
              title: e.Title,
            },
          })
        }
      },
    },
  }
</script>
<style lang="scss">
  .org-list {
    .org-list-tip {
      color: #c78a29;
      font-size: $font30;
      font-weight: 600;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
    .org-list-item-container {
      border-radius: 8px;
      padding: 10px;
      &.org-one {
        max-width: 400px;
      }
      // margin-top: 20px;
      .org-list-item {
        background: #fff;
        border: 1px solid #c78a29;
        border-radius: 5px;
        .org-list-item-img {
          img {
            width: 100%;
            border-radius: 5px 5px 0px 0px;
          }
        }
        .org-list-item-title {
          font-size: $font15;
          padding: 12px 0px;
          &.ch {
            font-size: $font15;
          }
        }
      }
    }
  }
  .org-type-menu {
    margin: 20px 0px;
    padding: 20px 20px 0px 20px;
    background: #ffffff;
    box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    // justify-content: space-around;
    display: flex;
    flex-flow: wrap;
    .org-type-item-container {
      margin-bottom: 20px;
      border-radius: 0;
      // width: calc(100% / 6);
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      border-right: 2px solid #d1d1d6;
      &:last-child {
        border-right: transparent;
      }
      .org-type-menu-item {
        font-size: $font22;
        font-weight: 600;
        cursor: pointer;
        &.active,
        &:hover,
        &:focus {
          color: #c41230;
        }
      }
    }
  }
</style>
