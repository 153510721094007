<template>
  <div class="detail-container">
    <site-slogan />
    <div class="detail-wrapper">
      <div class="detail-tip">
        <tip-ete :title="title" />
      </div>
      <template v-if="isMultImage">
        <div class="swiper-out-wrapper">
          <swiper-ete
            v-if="detail.list && detail.list.length"
            :swiper-list="detail.list"
          />
        </div>
      </template>
      <template v-else>
        <!-- <img
          v-if="detail.list && detail.list.length"
          class="detail-img"
          :src="detail.list[0].images"
        /> -->
        <!-- <div v-if="showDetails" class="detail-item">
          <div class="detail-abs">{{ detail.abstract }}</div>
        </div> -->
      </template>

      <div class="des">
        <div v-if="detail.content" v-html="detail.content"></div>
      </div>
      <diV v-if="isVideo" class="video-bar">
        <div v-if="detail.Address">
          <div class="video-box">
            <video-player
              ref="videoPlayer"
              class="video-player vjs-custom-skin"
              :options="playerOptions"
              :playsinline="true"
            />
          </div>
        </div>
      </diV>
    </div>
  </div>
</template>
<script>
  import { getArticle } from '@/api/site'
  import { SetLogoPathNames } from '@/utils/index'
  export default {
    name: 'Detail',
    data() {
      return {
        id: '',
        title: '',
        isMultImage: false,
        showDetails: true,
        isVideo: false,
        detail: {
          content: '',
          list: [],
          ArticleID: '',
          title: '',
          abstract: '',
          Address: '',
        },
        playerOptions: {
          aspectRatio: '16:9',
          autoplay: false,
          muted: false, // 默认情况下将会消除任何音频。
          language: 'zh-CN',
          loop: true, // 是否视频一结束就重新开始。
          volume: 0.3,
          playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
          // fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [],
          //   {
          //     type: 'video/mp4',
          //     src: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/videos/video16.mp4',
          //   },
          // ],
          poster: '',
          //   'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/videos/video16.mp4?x-oss-process=video/snapshot,t_2000',
          // controlBar: {
          //   // volumeControl: false,
          //   // timeDivider: false,
          //   // durationDisplay: false,
          //   // remainingTimeDisplay: true,
          //   // fullscreenToggle: true, // 全屏按钮
          // },
        },
      }
    },
    async created() {
      let params = this.$route.query
      if (params) {
        this.id = params.id
        this.title = params.title
        if (this.title) document.title = this.title
        this.isMultImage = params.isMultImage == '1'
        this.showDetails = params.sd !== '1'
        this.isVideo = params.vdo == 1
      }
      console.log(params, this.isMultImage)
      await this.getDetail()
    },
    methods: {
      setVideo() {
        const videoAddress = this.detail.Address || ''
        if (videoAddress) {
          const videoPoster =
            videoAddress + '?x-oss-process=video/snapshot,t_2000'

          this.playerOptions.sources = [
            {
              type: 'video/mp4',
              src: videoAddress,
            },
          ]
          this.playerOptions.poster = videoPoster
          this.$nextTick(() => {
            if (this.$refs.videoPlayer)
              this.$refs.videoPlayer.player.volume(0.5)
          })
        }
      },
      async getDetail() {
        if (this.id) {
          let param = { ArticleID: this.id }
          var res = await getArticle(param)
          if (res.data) {
            let data = res.data
            this.detail.ArticleID = data.ArticleID
            this.detail.title = data.Title || ''
            this.detail.abstract = data.Abstract || ''
            if (data.Contents) {
              this.detail.content = data.Contents
            }
            if (data.LogoPath && data.OssImagePath) {
              this.detail.list = SetLogoPathNames(data)
            }
            if (data.Address) {
              this.detail.Address = data.Address
              if (this.isVideo) {
                this.setVideo()
              }
            }
          }
        }
      },
    },
  }
</script>
<style lang="scss">
  .video-bar {
    margin-top: 15px;
  }
  .video-box {
    width: 100%;
    height: 100%;
    .vjs-custom-skin > .video-js .vjs-big-play-button {
      background-color: rgba(0, 0, 0, 0.45);
      font-size: 3.5em;
      border-radius: 50%;
      height: 2em !important;
      line-height: 2em !important;
      margin-top: -1em !important;
      margin-left: -1em !important;
      width: 2em !important;
      outline: none;
      border: none;
      .vjs-icon-placeholder::before {
        font-size: 3rem;
      }
    }
  }
</style>
