import { isJson } from '@/utils/validate'
const getLocalStorage = (key) => {
  const value = localStorage.getItem(key)
  if (isJson(value)) {
    return JSON.parse(value)
  } else {
    return false
  }
}
import { languageCacheKey, i18n } from '@/config'
const { language } = getLocalStorage(languageCacheKey)
const state = {
  banner: 'banner.png',
  thceclogo: 'menu/thceclogo.png',
  wceclogo: 'menu/wceclogo.png',
  homeTopUrl: 'hometop.jpg',
  mainbgUrl: '',
  ossBaseUrl: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/',
  itemTipUrl: 'itemtip.png',
  flowerUrl: 'flower.png',
  videoUrl: 'videos/video16.mp4',
  language: language || i18n,
  allKeys: {
    zh: {
      appnoticekey: 'AppNotice',
      //首页
      newskey: 'homeNew', //首页新闻
      epkey: '7X', //首页赞助企业
      agendaKey: 'homeDhrc', //首页日程
      homebannerkey: 'HomeBanners', //首页banner
      videoSID: 'BM', //首页视频
      agenda_EventAct_key: 'dZ',
      agenda_EventInfo_key: '68',
      agenda_ExhibitionInfo_key: '',
      entrconf_confintro_key: 'z2', //大会简介
      entrconf_organization_key: '8W', //召集人组织
      entrconf_advcommittee_key: 'vl', //顾问委员会
      entrconf16_Charity_key: '',
      entrconf16_Sponsors_key: '7X', //大会赞助企业
      entrconf16_OrgCommittee16_key: 'mk',
      entrconf16_ThaiCCOC_key: '',
      entrconf16_VirtualShowroom_key: '',
      entrconf16_WorkCommittee16_key: '',
      EventSvices_Accommodation_key: '17',
      EventSvices_BusinessTours_key: 'Xm',
      EventSvices_Documents_key: 'Docments',
      EventSvices_FoodBeverages_key: 'yQ',
      EventSvices_GolfMatch_key: 'gA',
      EventSvices_Transportation_key: '5m',
      EventSvices_TravelTips_key: 'nO',
      EventSvices_VenueIntro_key: 'Y6',
      newCenter_newlist_key: 'aW', //泰国中华总商会新闻
      newCenter_wnews_key: 'wrM', //第十六届世界华商大会新闻
      ContactusKey: '2r', //联系我们
      InvestThKey: 'Zx', //投资泰国
      m2key: '',
      m4key: '',
      m6key: 'Bb7',
      m8key: '',
      congletterkey: 'DHZHH',
    },
    en: {
      appnoticekey: 'AppNotice',
      //首页
      newskey: 'homeNew', //首页新闻
      epkey: 'vrl', //首页赞助企业
      agendaKey: 'homeDhrc', //首页日程
      homebannerkey: 'HomeBanners', //首页banner
      videoSID: 'rOy', //首页视频
      agenda_EventAct_key: '',
      agenda_EventInfo_key: '7zX',
      agenda_ExhibitionInfo_key: '',
      entrconf_confintro_key: 'Zgx', //
      entrconf_organization_key: 'JdV',
      entrconf_advcommittee_key: '2Yr',
      entrconf16_Charity_key: '',
      entrconf16_Sponsors_key: 'vrl',
      entrconf16_OrgCommittee16_key: 'e4B',
      entrconf16_ThaiCCOC_key: '',
      entrconf16_VirtualShowroom_key: '',
      entrconf16_WorkCommittee16_key: '',
      EventSvices_Accommodation_key: 'A6G', //酒店介绍
      EventSvices_BusinessTours_key: 'd4Z', //商务考察
      EventSvices_Documents_key: 'Docments', //文档下载
      EventSvices_FoodBeverages_key: '6e8', //餐饮介绍
      EventSvices_GolfMatch_key: 'YY6', //高尔夫友谊赛
      EventSvices_Transportation_key: 'lae', //交通介绍
      EventSvices_TravelTips_key: '4PE', //旅游介绍
      EventSvices_VenueIntro_key: 'aXW', //会场介绍
      newCenter_newlist_key: 'KZO',
      newCenter_wnews_key: 'Xeb',
      ContactusKey: 'Rmk',
      InvestThKey: 'O0W',
      m2key: '',
      m4key: '',
      m6key: 'r5w',
      m8key: '',
      congletterkey: 'DHZHH',
    },
    th: {
      appnoticekey: 'AppNotice',
      //首页
      newskey: 'homeNew', //首页新闻
      epkey: 'MAq', //首页赞助企业
      agendaKey: 'homeDhrc', //首页日程
      homebannerkey: 'HomeBanners', //首页banner
      videoSID: '7g2', //首页视频
      agenda_EventAct_key: '',
      agenda_EventInfo_key: 'oaP',
      agenda_ExhibitionInfo_key: '',
      entrconf_confintro_key: 'oaV', //
      entrconf_organization_key: 'kQX',
      entrconf_advcommittee_key: '0PG',
      entrconf16_Charity_key: '',
      entrconf16_Sponsors_key: 'MAq',
      entrconf16_OrgCommittee16_key: 'rOw',
      entrconf16_ThaiCCOC_key: '',
      entrconf16_VirtualShowroom_key: '',
      entrconf16_WorkCommittee16_key: '',
      EventSvices_Accommodation_key: 'RR3', //酒店介绍
      EventSvices_BusinessTours_key: '27y', //商务考察
      EventSvices_Documents_key: 'Docments', //文档下载
      EventSvices_FoodBeverages_key: 'ZZB', //餐饮介绍
      EventSvices_GolfMatch_key: 'xzK', //高尔夫友谊赛
      EventSvices_Transportation_key: '9Vx', //交通介绍
      EventSvices_TravelTips_key: 'JJl', //旅游介绍
      EventSvices_VenueIntro_key: 'OPy', //会场介绍
      newCenter_newlist_key: 'QPg',
      newCenter_wnews_key: 'g84',
      ContactusKey: 'q0p',
      InvestThKey: 'bA1',
      m2key: '',
      m4key: '',
      m6key: 'WQd',
      m8key: '',
      congletterkey: 'DHZHH',
    },
  },
}
const mutations = {
  changeLanguage(state, language) {
    state.language = language
    localStorage.setItem(languageCacheKey, `{"language":"${language}"}`)
  },
}
const actions = {
  changeLanguage: ({ commit }, language) => {
    commit('changeLanguage', language)
  },
}
const getters = {
  banner: (state) => state.ossBaseUrl + state.banner,
  thceclogo: (state) => state.ossBaseUrl + state.thceclogo,
  wceclogo: (state) => state.ossBaseUrl + state.wceclogo,
  homeTopUrl: (state) => state.ossBaseUrl + state.homeTopUrl,
  ossBaseUrl: (state) => state.ossBaseUrl,
  itemTipUrl: (state) => state.ossBaseUrl + state.itemTipUrl,
  getLanguage: (state) => state.language,
  flowerUrl: (state) => state.ossBaseUrl + state.flowerUrl,
  videoUrl: (state) => state.ossBaseUrl + state.videoUrl,
  queryKeys: (state) => {
    switch (state.language) {
      case 'en':
        return state.allKeys.en
      case 'th':
        return state.allKeys.th
      default:
        return state.allKeys.zh
    }
  },
}
export default { state, getters, mutations, actions }
