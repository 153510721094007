export default {
  vabI18n: {
    首页: 'Home',
    展会新闻: 'Exh News',
    更多: 'more',
    没有更多了: 'ending',
    世界华商大会: 'WCEC',
    大会介绍: 'Introduction',
    召集人组织: 'Convener Oanization',
    顾问委员会: 'Advisory Committee',
    往届回顾: 'View History',
    第十六届华商大会: '16th WCEC',
    十六届组织委员会: '16th Organizing Committee',
    十六届工作委员会: '16th Working Committee',
    泰国中华总商会: 'Thai-Chinese Chamber of Commerce',
    大会赞助企业: 'Sponsors',
    慈善公益: 'Charity',
    线上展厅: 'Virtual Showroom',
    新闻中心: 'Press Center',
    新闻列表: 'Press Release',
    第十六届世界华商大会新闻: 'Press Release',
    泰国中华总商会新闻: '泰国中华总商会新闻',
    图文集: 'Albumns',
    新闻图片: 'Photos',
    新闻视频: 'Videos',
    大会直播: 'Livestream',

    大会日程: 'Agenda',
    大会资讯: 'Event Information',
    展览资讯: 'Exhibition Information',
    大会活动: 'Event Activities',
    主要活动流程: 'Event Activities',

    投资泰国: 'Thai Investment',
    大会服务: 'Event Servcies',
    会场介绍: 'Venue Introduction',
    交通介绍: 'Transportation',
    酒店介绍: 'Accommodation',
    餐饮介绍: 'Food & Beverages',
    旅游介绍: 'Travel Tips',
    商务考察: 'Business Tours',
    高尔夫友谊赛: 'Golf Match',
    文档下载: 'Documents',
    联系我们: 'Contact Us',
    展商展示: 'Exhibitor Center',
    大会祝贺函: '大会祝贺函',
    团体报名: 'Group registration',
    嘉宾注册: 'Registration',
    视频: 'Videos',
    大会最新新闻: 'Latest Press',
    大会新闻: 'Event Press',
    敬请期待: 'Coming Soon',
    系统提示: 'System',
    地址头: 'Venue:',
    主会场头: 'Venue: ',
    日期头: 'Time: ',
  },
}
