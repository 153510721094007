/**
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import router from '@/router'
// import store from '@/store'

router.beforeEach(async (to, from, next) => {
  console.log('router.beforeEach', to, from)
  next()
})
// router.afterEach((to) => {})
