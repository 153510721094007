<template>
  <div class="flower-title">
    <div class="flower"><img :src="flower" /></div>
    <div class="title">{{ title }}</div>
    <div class="flower"><img :src="flower" /></div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'SubTip',
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters({ flower: 'settings/flowerUrl' }),
    },
  }
</script>
<style lang="scss">
  .flower-title {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      margin: 0px 20px;
    }
    .flower {
      img {
        width: 14px;
      }
    }
  }
</style>
