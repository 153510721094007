<template>
  <div class="site-layout">
    <div class="site-layout-header sticky">
      <site-header />
    </div>
    <div class="site-main main-padding">
      <site-app-main />
    </div>
    <el-backtop target="#app" />
  </div>
</template>
<script>
  export default {
    name: 'Layout',
  }
</script>
