import {
  getArticleListByType,
  getArticleList,
  getArticlePageList,
  tagList,
  tagFiles,
  getArticlePageListByType,
  getNextArticleList,
  getArticleByType,
} from '@/api/site'
import { isFunc } from '@/utils/validate'
import { formatDate, sortbydate } from '@/utils/index'
function dealResult(res, func1, func2) {
  let list = []
  if (res && res.code == 200) {
    if (res.data) {
      list = res.data
      if (func1 && isFunc(func1)) func1(list)
    } else {
      if (func2 && isFunc(func2)) func2(res)
    }
  }
  return list
}
function dealPageResult(res, func1, func2) {
  let data
  if (res && res.code == 200) {
    if (res.data) {
      data = res.data
      if (func1 && isFunc(func1)) func1(data)
    } else {
      if (func2 && isFunc(func2)) func2(res)
    }
  }
  return data
}
export async function _GetArticleListByType(stype, func1, func2) {
  let res = await getArticleListByType({ SType: stype })
  dealResult(res, func1, func2)
}
export async function _GetArticleByType(stype, func1, func2) {
  let res = await getArticleByType({ SType: stype })
  dealResult(res, func1, func2)
}
export async function _GetArticlePageListByType(
  stype,
  pageIndex = 1,
  pageSize = 10,
  func1,
  func2
) {
  let res = await getArticlePageListByType({
    SType: stype,
    pageIndex: pageIndex,
    pageSize: pageSize,
  })
  dealPageResult(res, func1, func2)
}
export async function _GetArticlePageListBySID(
  SID,
  pageIndex = 1,
  pageSize = 10,
  func1,
  func2,
  detail = false
) {
  let res = await getArticlePageList({
    SID: SID,
    pageIndex: pageIndex,
    pageSize: pageSize,
    HasDetail: detail,
  })
  dealPageResult(res, func1, func2)
}
export async function _GetArticleListBySID(sid, func1, func2) {
  dealResult(await getArticleList({ SID: sid }), func1, func2)
}
export async function _GetNextArticleListBySID(sid, func1, func2) {
  dealResult(await getNextArticleList({ SID: sid }), func1, func2)
}
export async function _GetTags(key, func1, func2) {
  dealResult(await tagList({ TagKey: key }), func1, func2)
}
export async function _GetTagFiles(key, func1, func2) {
  dealResult(await tagFiles({ TagKey: key }), func1, func2)
}
export function DealAgendaData(list, isSort) {
  let result = []
  console.log('DealAgendaData', list)
  //去除Menuarticle
  let artList = list.filter((f) => f.ContentType == 'ListArticle')
  let menuArtList = list.filter((f) => f.ContentType == 'MenuArticle')
  // console.log('artList', menuArtList)
  artList.forEach((item) => {
    let curDate = formatDate(item.ReleaseTime)
    let curagenda = result.find((r) => r.date == curDate.date)
    if (curagenda) {
      curagenda['list'] = curagenda['list'] || []
      curagenda['list'].push(item)
    } else {
      let agendas = {}
      agendas['date'] = curDate.date
      agendas['sourcedate'] = item.ReleaseTime
      agendas['formatdate'] = curDate
      agendas['menuarticle'] = menuArtList.find(
        (r) => r.ReleaseTime == item.ReleaseTime
      )
      agendas['list'] = []
      agendas['list'].push(item)
      result.push(agendas)
    }
  })
  // console.log('DealAgendaData-result', result)
  return isSort ? sortbydate(result) : result
}
export function GetFinalAgendaList(list) {
  let sortList = DealAgendaData(list, true)
  console.log('sortList', sortList)
  let newGroupList = []
  let totalCount = sortList.length
  console.log('totalCount', totalCount / 2)
  let totalIndex = 0
  if (totalCount > 0) {
    if (totalCount <= 2) totalIndex = 1
    else if (totalCount % 2 == 0) totalIndex = totalCount / 2
    else totalIndex = totalCount / 2 + 1
  }
  console.log('totalIndex', totalCount / 2 == 0)
  for (let i = 1; i <= totalCount; i++) {
    newGroupList.push({
      rowid: i,
      list: sortList.splice(0, totalIndex),
    })
  }
  // console.log('newGroupList', newGroupList)
  newGroupList.forEach((o1) => {
    o1.list.forEach((o2) => {
      let tempList = []
      o2.list.forEach((o3) => {
        let exist = tempList.find(
          (i) => i.ReleaseTimeText == o3.ReleaseTimeText && o3.ReleaseTimeText
        )
        if (exist) {
          exist.list.push(o3)
        } else {
          tempList.push({
            ReleaseTimeText: o3.ReleaseTimeText,
            list: [o3],
          })
        }
      })
      // console.log('tempList', tempList)
      o2['newlist'] = tempList
    })
  })
  return newGroupList
}
// export function GetFinalAgendaList(list) {
//   let sortList = DealAgendaData(list)
//   console.log('sortList', sortList)
//   let newGroupList = []
//   let totalCount = sortList.length
//   let totalIndex = 0
//   if (totalCount > 0) {
//     if (totalCount <= 2) totalIndex = 1
//     else if (totalCount / 2 == 0) totalIndex = totalCount / 2
//     else totalIndex = totalCount / 2 + 1
//   }

//   for (let i = 1; i <= totalCount; i++) {
//     newGroupList.push({
//       rowid: i,
//       list: sortList.splice(0, totalIndex),
//     })
//   }
//   // console.log('newGroupList', newGroupList)
//   newGroupList.forEach((o1) => {
//     o1.list.forEach((o2) => {
//       let tempList = []
//       o2.list.forEach((o3) => {
//         let exist = tempList.find(
//           (i) => i.ReleaseTimeText == o3.ReleaseTimeText && o3.ReleaseTimeText
//         )
//         if (exist) {
//           exist.list.push(o3)
//         } else {
//           tempList.push({
//             ReleaseTimeText: o3.ReleaseTimeText,
//             list: [o3],
//           })
//         }
//       })
//       // console.log('tempList', tempList)
//       o2['newlist'] = tempList
//     })
//   })
//   return newGroupList
// }
