export default {
  vabI18n: {
    首页: '首页',
    展会新闻: '展会新闻',
    更多: '更多',
    没有更多了: '没有更多了',
    世界华商大会: '世界华商大会',
    大会介绍: '大会介绍',
    召集人组织: '召集人组织',
    顾问委员会: '顾问委员会',
    往届回顾: '往届回顾',
    第十六届华商大会: '第十六届华商大会',
    十六届组织委员会: '十六届组织委员会',
    十六届工作委员会: '十六届工作委员会',
    泰国中华总商会: '泰国中华总商会',
    大会赞助企业: '大会赞助企业',
    慈善公益: '慈善公益',
    线上展厅: '线上展厅',
    新闻中心: '新闻中心',
    新闻列表: '新闻列表',
    第十六届世界华商大会新闻: '第十六届世界华商大会新闻',
    泰国中华总商会新闻: '泰国中华总商会新闻',
    图文集: '图文集',
    新闻图片: '新闻图片',
    新闻视频: '新闻视频',
    大会直播: '大会直播',

    大会日程: '大会日程',
    大会资讯: '大会资讯',
    展览资讯: '展览资讯',
    大会活动: '大会活动',
    主要活动流程: '主要活动流程',

    投资泰国: '投资泰国',
    大会服务: '大会服务',
    会场介绍: '会场介绍',
    交通介绍: '交通介绍',
    酒店介绍: '酒店介绍',
    餐饮介绍: '餐饮介绍',
    旅游介绍: '旅游介绍',
    商务考察: '商务考察',
    高尔夫友谊赛: '高尔夫友谊赛',
    文档下载: '文档下载',
    联系我们: '联系我们',
    展商展示: '展商展示',
    大会祝贺函: '大会祝贺函',
    团体报名: '团体报名',
    嘉宾注册: '嘉宾注册',
    视频: '视频',
    大会最新新闻: '大会最新新闻',
    大会新闻: '大会新闻',
    敬请期待: '敬请期待',
    系统提示: '系统提示',
    地址头: '地址：',
    主会场头: '主会场：',
    日期头: '日期：',
  },
}
