<template>
  <div class="artlist-container">
    <item-tip />
    <new-list
      v-if="list.length"
      :list="list"
      :show-more="showMore"
      :stype="listkey"
      :title="title"
      @articleClick="articleClick"
    />
  </div>
</template>
<script>
  export default {
    name: 'Artlist',
    data() {
      return {
        showMore: false,
        list: [],
        title: '',
        listkey: '',
        keytype: '',
      }
    },
    async created() {
      await this.getlist()
    },
    methods: {
      async getlist() {},
      articleClick(e) {
        console.log('articleClick', e)
        if (e.OutLink) {
          window.location.href = e.OutLink
        } else {
          this.$router.push({
            path: 'detail',
            query: {
              id: e.ArticleID,
              title: e.Title,
            },
          })
        }
      },
    },
  }
</script>
<style></style>
