export default {
  vabI18n: {
    首页: 'หน้าหลัก',
    展会新闻: '展会新闻',
    更多: '更多',
    没有更多了: 'ending',
    世界华商大会: 'การประชุมนักธุรกิจชาวจีนโลก',
    大会介绍: 'บทนำการประชุม',
    召集人组织: 'องค์กรผู้ก่อตั้ง',
    顾问委员会: 'คณะกรรมการที่ปรึกษา',
    往届回顾: 'การประชุมที่ผ่านมา',
    第十六届华商大会: 'การประชุมนักธุรกิจชาวจีนโลก ครั้งที่ 16',
    十六届组织委员会: 'คณะกรรมการจัดประชุมนักธุรกิจชาวจีนโลก ครั้งที่ 16',
    十六届工作委员会: 'คณะทำงานจัดประชุมนักธุรกิจชาวจีนโลก ครั้งที่ 16',
    泰国中华总商会: 'หอการค้าไทย-จีน',
    大会赞助企业: 'ผู้สนับสนุนการประชุม WCEC',
    慈善公益: 'กิจกรรมการกุศล',
    线上展厅: 'นิทรรศการออนไลน์',

    新闻中心: 'ข่าวประชาสัมพันธ์',
    中华总商会新闻: 'ข่าวสารหอการค้าไทย-จีน',
    第十六届世界华商大会新闻: 'ข่าวสารการประชุมนักธุรกิจจีนโลกครั้งที่ 16',
    泰国中华总商会新闻: '泰国中华总商会新闻',
    新闻列表: 'รายการข่าว',
    图文集: 'แกลเลอรี่รูปภาพ',

    新闻图片: 'ภาพข่าว',
    新闻视频: 'ข่าววิดีโอ',
    大会直播: 'ไลฟ์สดการประชุม',

    大会日程: 'กำหนดการประชุม',
    大会资讯: 'ข้อมูลการประชุม',
    展览资讯: 'ข้อมูลนิทรรศการ',
    大会活动: 'กิจกรรมการประชุม',
    主要活动流程: 'กิจกรรมการประชุม',

    投资泰国: 'ข้อมูลการลงทุนในไทย',
    大会服务: 'การบริการประชุม',
    会场介绍: 'แนะนำสถานที่ประชุม',
    交通介绍: 'แนะนำการเดินทาง',
    酒店介绍: 'แนะนำโรงแรมที่พัก',
    餐饮介绍: 'แนะนำอาหารการกิน',
    旅游介绍: 'แนะนำการท่องเที่ยว',
    商务考察: 'ศึกษาดูงาน',
    高尔夫友谊赛: 'การแข่งกอล์ฟกระชับมิตร',

    文档下载: 'ดาวน์โหลดเอกสาร',
    联系我们: 'ติดต่อเรา',
    展商展示: 'รายชื่อผู้แสดงสินค้า',
    大会祝贺函: '大会祝贺函',
    团体报名: 'ลงทะเบียนกลุ่ม',
    嘉宾注册: 'การลงทะเบียน',
    视频: 'วีดีโอ',
    大会最新新闻: 'ข่าวสารการประชุมล่าสุด',
    大会新闻: 'ข่าวการชุมนุม',
    敬请期待: 'โปรดติดตาม',
    系统提示: 'System',
    地址头: 'สถานที่:',
    主会场头: 'สถานที่: ',
    日期头: 'เวลา: ',
  },
}
