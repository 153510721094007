<template>
  <div class="detail-wrapper">
    <div class="detail-tip">
      <tip-ete :title="title" />
    </div>
    <template v-if="isMultImage">
      <div class="swiper-out-wrapper">
        <swiper-ete
          v-if="article.list && article.list.length"
          :swiper-list="article.list"
        />
      </div>
    </template>
    <template v-else>
      <img
        v-if="article.list && article.list.length && showLogo"
        class="detail-img"
        :src="article.list[0].images"
      />
      <div v-if="showDetails" class="detail-item">
        <!-- <div class="detail-title">{{ detail.title }}</div> -->
        <div class="detail-abs">{{ article.abstract }}</div>
      </div>
    </template>

    <div class="des">
      <div v-if="article.content" v-html="article.content"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MenuArticle',
    props: {
      title: {
        type: String,
        default: '',
      },
      isMultImage: {
        type: Boolean,
        default: false,
      },
      showDetails: {
        type: Boolean,
        default: true,
      },
      showLogo: {
        type: Boolean,
        default: true,
      },
      article: {
        type: Object,
        default() {
          return {}
        },
      },
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {}
    },
    methods: {},
  }
</script>
