<template>
  <footer class="site-footer">
    <div class="content">
      <!-- <div class="item">联系我们：010-5338 7586 topex@topexpo.cc</div>
      <div class="item fl">
        大会官方网站内容未经允许，禁止拷贝或转载本站内容，禁止建立类似会议注册页面，否则追究法律责任！
      </div>
      <div class="item ba">
        <div>
          技术支持：Info Salons 北京华恺国际展览有限公司版权所有
          <div style="width: 300px; margin: 0 auto; padding: 20px 0">
            <a
              href="#"
              style="
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
              "
            >
              <img src="" style="float: left" />
              <p
                style="
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                  color: #ffffff;
                "
              >
                <img
                  src="https://iscfiles.oss-accelerate.aliyuncs.com/tmp/ETE/web/iconbottom.png"
                  style="vertical-align: bottom"
                />
                京ICP备 19042023号-5
              </p>
            </a>
          </div>
        </div>
      </div> -->
      <div>
        <img
          class="imgfoot"
          src="https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/footer.png"
        />
      </div>
      <div class="item">大会主办方：泰国中华总商会</div>
      <div class="item">泰国曼谷沙吞南路四十三号泰国中华总商会大厦第九楼</div>
      <!-- <div class="item">Tel：+662-6758577-84</div>
      <div class="item">Fax：+662-2123916</div> -->
      <div class="item">Email：info@16th-wcec.org</div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'SiteFooter',
  }
</script>
<style>
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
</style>
