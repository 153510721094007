<template>
  <div class="detail-container">
    <site-slogan />
    <div class="detail-wrapper">
      <div class="detail-tip">
        <tip-ete :title="title" />
      </div>
      <!-- <div v-if="showDetails" class="detail-item">
        <div class="detail-abs">{{ detail.abstract }}</div>
      </div> -->
      <div class="des">
        <div v-if="detail.content" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { getMenuArticle } from '@/api/site'
  import { SetLogoPathNames } from '@/utils/index'
  export default {
    name: 'MenuDetail',
    data() {
      return {
        menuid: '',
        menukey: '',
        title: '',
        isMultImage: false,
        showDetails: true,
        detail: {
          content: '',
          list: [],
          ArticleID: '',
          title: '',
          abstract: '',
        },
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
    },
    async created() {
      let params = this.$route.query
      if (params) {
        this.menukey = params.menukey
        this.title = params.title
        if (this.title) document.title = this.title
        // this.isMultImage = params.isMultImage == '1'
        // this.showDetails = params.sd !== '1'
      }
      this.setKeys()
      await this.getDetail()
    },
    async activated() {
      this.setKeys()
    },
    methods: {
      setKeys() {
        this.menuid = this.queryKeys[this.menukey]
      },
      async getDetail() {
        if (this.menuid) {
          let param = { SID: this.menuid }
          var res = await getMenuArticle(param)
          if (res.data) {
            let data = res.data
            this.detail.ArticleID = data.ArticleID
            this.detail.title = data.Title || ''
            this.detail.abstract = data.Abstract || ''
            if (data.Contents) {
              this.detail.content = data.Contents
            }
            if (data.LogoPath && data.OssImagePath) {
              this.detail.list = SetLogoPathNames(data)
            }
          }
        }
      },
    },
  }
</script>
<style lang="scss"></style>
