<template>
  <div class="content-container">
    <site-slogan />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <div class="list-wrapper">
        <el-row class="list" :gutter="20">
          <template v-for="(item, index) in list">
            <el-col
              :key="index"
              class="list-item-wrapper"
              :md="6"
              :sm="8"
              :xs="24"
            >
              <div class="list-item-adv" @click="articleClick(item)">
                <div class="list-tip-adv txt-one-line">{{ item.Address }}</div>
                <div class="list-content-adv">
                  <div class="list-media-adv">
                    <img class="" :src="item.OssImagePath + item.LogoPath" />
                  </div>
                  <div class="list-title-adv txt-one-line">
                    {{ item.Title }}
                  </div>
                  <div class="list-text-adv txt-two-line">
                    {{ item.TitleEN || translateTitle('敬请期待') }}
                  </div>
                </div>
              </div>
            </el-col>
          </template>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  import { _GetArticlePageListBySID } from '@/utils/deal'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Advcommittee',
    data() {
      return {
        list: [],
        title: '顾问委员会',
        newskey: 'vl',
        pageSize: 100,
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
    },
    async created() {
      this.setKeys()
      await this.getlist()
    },
    async activated() {
      this.setKeys()
      await this.getlist()
    },
    methods: {
      setKeys() {
        this.newskey = this.queryKeys.entrconf_advcommittee_key
      },
      translateTitle,
      async getlist() {
        await this.querylist(1, this.pageSize)
      },
      async querylist(pageIndex, pageSize) {
        await _GetArticlePageListBySID(
          this.newskey,
          pageIndex,
          pageSize,
          (data) => {
            console.log(data)
            if (data) {
              this.list = [...this.list, ...data.list]
              if (data.total == this.list.length) {
                this.isComplete = true
                this.showMore = false
              }
            }
          }
        )
      },
      async moreEvent(e) {
        if (this.isComplete) return
        this.currentPageIndex++
        console.log('moreEvent', e)
        await this.querylist(this.currentPageIndex, this.pageSize)
      },
      articleClick(e) {
        console.log('articleClick', e)
        if (e.OutLink) {
          window.open(e.OutLink)
        } else {
          this.$router.push({
            path: '/detail',
            query: {
              id: e.ArticleID,
              title: e.Title,
            },
          })
        }
      },
    },
  }
</script>
<style lang="scss">
  .list-item-adv {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.12);
    padding: 5px 20px;
    .list-tip-adv {
      padding-top: 20px;
      font-size: 22px;
      line-height: 30px;
    }
    .list-content-adv {
      border-top: 2px solid #c78a29;
      margin: 20px;

      .list-title-adv {
        margin: 20px 0px 15px 0px;
        font-weight: bold;
        // margin-bottom: 10px;
        font-size: 15px;
        // height: 42px;
        overflow: hidden;
      }
      .list-text-adv {
        // text-align: left;
        // margin: 20px;
        // font-size: 14px;
        height: 35px;
      }
    }
    .list-media-adv {
      width: 100%;
      height: 100px;
      padding: 15px;
      img {
        object-fit: contain;
        // margin: 0 auto;
        width: 100px;
        height: 100px;
        // display: block;
      }
    }
  }
</style>
