<template>
  <div class="content-container">
    <site-slogan />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <image-list :list="epList" />
    </div>
  </div>
</template>
<script>
  import { _GetArticleListBySID } from '@/utils/deal'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Sponsors',
    data() {
      return {
        epkey: '7X',
        epList: [],
        title: '大会赞助企业',
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
    },
    async created() {
      this.setKeys()
      await this.getEnterpriseList()
    },
    async activated() {
      this.setKeys()
    },
    methods: {
      setKeys() {
        this.epkey = this.queryKeys.entrconf16_Sponsors_key
      },
      async getEnterpriseList() {
        await _GetArticleListBySID(this.epkey, (list) => {
          var temp = []
          if (list) {
            list.forEach((item) => {
              temp.push({ src: item.OssImagePath + item.LogoPath })
            })
          }
          this.epList = temp
        })
      },
    },
  }
</script>
