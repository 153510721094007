import { getToken, removeToken, setToken } from '@/utils/token'
const state = {
  userinfo: '',
  token: getToken(),
}
const getters = {
  userinfo: (state) => state.userinfo,
  token: (state) => state.token,
}
const mutations = {
  setUserInfo(state, userinfo) {
    state.userinfo = userinfo
  },
  setToken(state, token) {
    state.token = token
    setToken(token)
  },
}
const actions = {
  async resetAll({ dispatch }) {
    await dispatch('setToken', '')
    removeToken()
  },
  setUserInfo({ commit }, userinfo) {
    commit('setUserInfo', userinfo)
  },
  setToken({ commit }, token) {
    commit('setToken', token)
  },
}
export default { state, getters, mutations, actions }
