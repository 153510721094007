<template>
  <div class="detail-container">
    <site-slogan />
    <div class="detail-wrapper">
      <site-article :query-key="queryKey" :title="'大会资讯'" />
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'EventInfo',
    data() {
      return {
        queryKey: '68',
      }
    },
    computed: {
      ...mapGetters({
        queryKeys: 'settings/queryKeys',
      }),
    },
    created() {
      this.setKeys()
    },
    activated() {
      this.setKeys()
    },
    methods: {
      setKeys() {
        this.queryKey = this.queryKeys.agenda_EventInfo_key
      },
    },
  }
</script>
