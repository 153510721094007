<template>
  <div class="content-container">
    <item-tip />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <new-list
        v-if="list.length"
        :list="list"
        :show-more="showMore"
        :stype="newskey"
        @articleClick="articleClick"
      />
    </div>
  </div>
</template>
<script>
  import { _GetArticleListByType } from '@/utils/deal'
  export default {
    name: 'News',
    data() {
      return {
        showMore: false,
        list: [],
        title: '展会新闻',
        newskey: 'ZHXWLIST',
      }
    },
    async activated() {
      await this.getlist()
    },
    methods: {
      async getlist() {
        await _GetArticleListByType(this.newskey, (list) => {
          if (list) {
            this.list = list
          }
        })
      },
      articleClick(e) {
        console.log('articleClick', e)
        if (e.OutLink) {
          window.open(e.OutLink)
        } else {
          this.$router.push({
            path: 'detail',
            query: {
              id: e.ArticleID,
              title: e.Title,
            },
          })
        }
      },
    },
  }
</script>
<style lang="scss"></style>
