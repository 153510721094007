<template>
  <div v-if="show" class="site-dialog">
    <diV class="site-dialog-close" @click="show = false">
      <i class="el-icon-close"></i>
    </diV>
    <diV class="site-notice-dialog-box">
      <div v-show="title" class="site-dialog-header">{{ title }}</div>
      <div class="site-notice-content">
        <slot></slot>
      </div>
    </diV>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'SiteNotice',
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        show: false,
      }
    },
    computed: {
      ...mapGetters({ flower: 'settings/flowerUrl' }),
    },
    methods: {
      showDialog() {
        this.show = true
      },
      hideDialog() {
        this.show = false
      },
    },
  }
</script>
<style lang="scss">
  .site-dialog {
    .site-notice-dialog-box {
      width: 90%;
      padding: 0px;
      border: 0px solid #ebeef5;
      max-width: 800px;
      // max-height: 80%;
      // background-color: #fff;
      border-radius: 4px;
      font-size: 18px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      text-align: left;
      overflow: auto;
      backface-visibility: hidden;
      position: relative;
      .site-notice-content {
        padding: 0px;
        color: #606266;
        font-size: 14px;
        overflow: auto;
        font-size: 0;
        background: none;
        width: 100%;
        margin: 0 auto;
        img {
          border-radius: 4px;
          display: block;
          // height: 100%;
          width: 100%;
        }
      }
    }
  }
</style>
