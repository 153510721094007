<template>
  <div class="item" :class="{ first: total == count }">
    <!-- <div class="item-base"></div> -->
    <template v-if="total < count">
      <site-sub-circle :count="count" :total="total + 1" :type="type" />
    </template>
  </div>
</template>
<script>
  export default {
    name: 'SiteSubCircle',
    props: {
      count: {
        type: Number,
        default: 1,
      },
      total: {
        type: Number,
        default: 0,
      },
    },

    data() {
      return {}
    },
    created() {
      console.log(this.count)
    },
    methods: {},
  }
</script>
<style lang="scss"></style>
