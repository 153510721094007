import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import '@/common'

Vue.config.productionTip = false

store.dispatch('routes/setRoutes')
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
Vue.use(VideoPlayer)
