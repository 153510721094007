<template>
  <div class="site-org">
    <site-dialog ref="siteDialogRef" :title="title">
      <div class="site-org-wrapper">
        <div class="site-org-left">
          <img :src="orgitem.OssImagePath + orgitem.LogoPath" />
        </div>
        <div class="site-org-right">
          <div class="title">{{ orgitem.Title }}</div>
          <div class="des">{{ orgitem.Abstract }}</div>
        </div>
      </div>
    </site-dialog>
  </div>
</template>
<script>
  export default {
    name: 'SiteOrg',
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        orgitem: '',
      }
    },
    methods: {
      showDialog(item) {
        this.orgitem = item
        this.$refs.siteDialogRef.showDialog()
      },
    },
  }
</script>
<style lang="scss">
  .site-org {
    .site-org-wrapper {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: row;
      .site-org-left {
        width: 40%;
        border-radius: 8px;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
      .site-org-right {
        padding-left: 20px;
        flex: 1;
        .title {
          font-size: $font20;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .des {
        }
      }
    }
  }
  @media (max-width: 500px) {
    .site-org {
      .site-org-wrapper {
        flex-direction: column;
        .site-org-left {
          width: 100%;
        }
        .site-org-right {
          padding-left: 0px;
          .title {
            margin-top: 10px;
          }
        }
      }
    }
  }
</style>
