<template>
  <div class="slogan">
    <!-- <span>{{ slogan }}</span> v-if="listData && listData.length" -->
    <!-- <vue-seamless-scroll
      class="warp"
      :class-option="classOption"
      :data="listData"
    >
      <ul class="ul-item">
        <li v-for="(item, index) in listData" :key="index" class="li-item">
          <i class="el-icon-mic" style="color: "></i>
          <span>{{ item }}</span>
        </li>
      </ul>
     
    </vue-seamless-scroll> -->
    <vue-seamless-scroll
      class="seamless-warp2"
      :class-option="classOption"
      :data="listData"
    >
      <!-- <ul class="item">
        <li v-for="(item, index) in listData" :key="index" v-text="item"></li>
      </ul> -->
      <span
        v-for="(item, index) in listData"
        :key="index"
        class="item"
        :class="{ zh: index == 0 }"
      >
        {{ item }}
      </span>
    </vue-seamless-scroll>
  </div>
</template>
<script>
  import { _GetArticleListByType } from '@/utils/deal'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: 'SiteSlogan',
    components: {
      vueSeamlessScroll,
    },
    data() {
      return {
        slogan: '',
        classOption: {
          limitMoveNum: 1,
          step: 0.5,
          direction: 3,
        },
        listData: [],
      }
    },
    async created() {
      await this.getSlogan()
    },
    async activated() {
      await this.getSlogan()
    },
    methods: {
      async getSlogan() {
        await _GetArticleListByType('HomeSlogan', (list) => {
          const artList = list.filter(
            (item) => item.ContentType == 'ListArticle'
          )
          // console.log(artList)

          if (artList && artList.length) {
            let temp = []
            artList.forEach((item) => {
              temp.push(item.Abstract || '')
            })
            this.listData = temp
          }
          // console.log('this.listData', this.listData)
        })
      },
    },
  }
</script>
<style lang="scss">
  .seamless-warp2 {
    overflow: hidden;
    height: 50px;
    line-height: 50px;
    width: 100%;
    margin: 0 auto;

    .item {
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding: 0;
      color: #fff;
      margin-right: 20px;
      white-space: nowrap;
      font-size: 18px;

      color: #171b3d;
      line-height: 50px;
      &.zh {
        font-family: KaiTi;
        font-size: 20px;
        font-weight: 600;
      }
    }
    ul.item {
      margin: 0 autuo;
      list-style: none;
      padding: 0 !important;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      // justify-content: center;
      align-items: center;
      li {
        height: 50px;
        line-height: 50px;
        padding: 0;
        color: #fff;
        margin-right: 10px;
        white-space: nowrap;
        font-size: 18px;
        // font-family: KaiTi;
        color: #ffffff;
        line-height: 50px;
      }
    }
  }
  .slogan {
    width: 100%;
    height: 50px;
    padding: 0px 20px;
    position: relative;
    z-index: 10;
    // background: linear-gradient(
    //   90deg,
    //   #f5e693 0%,
    //   #cc9936 29%,
    //   #cc9935 64%,
    //   #f2e28d 69%,
    //   #cc9935 77%,
    //   #c99e3b 100%
    // );
    // background: #171b3d;
    background: linear-gradient(
      90deg,
      #f5e693 0%,
      #cc9936 29%,
      #cc9935 64%,
      #f2e28d 69%,
      #cc9935 77%,
      #c99e3b 100%
    );
    // span {
    //   font-family: KaiTi;
    //   font-weight: 600;
    //   color: #ffffff;
    //   line-height: 50px;
    //   font-size: 18px;
    // }
  }
  // .warp {
  //   width: 200px;
  //   height: 48px;
  //   // margin: 0 auto;
  //   // overflow: hidden;
  //   ul.ul-item {
  //     list-style: none;
  //     padding: 0;
  //     margin: 0 auto;
  //     display: flex;
  //     width: 1000px;
  //     height: 48px;
  //     flex-wrap: nowrap;
  //     li.li-item {
  //       width: 100%;
  //       white-space: nowrap;
  //       // float: left;
  //       // margin-right: 10px;
  //       // // width: 100px;
  //       // // height: 48px;
  //       // // line-height: 48px;
  //       // color: #fff;
  //       // // text-align: center;
  //       // font-size: 18px;
  //     }
  //   }
  // }
</style>
