<template>
  <div class="content-container">
    <site-slogan />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <div class="list-wrapper">
        <el-row class="list" :gutter="20">
          <template v-for="(item, index) in list">
            <el-col
              :key="index"
              class="list-item-wrapper"
              :md="8"
              :sm="12"
              :xs="24"
            >
              <div class="list-item-org" @click="articleClick(item)">
                <div class="list-media-org">
                  <img class="" :src="item.OssImagePath + item.LogoPath" />
                </div>
                <div class="list-content-org">
                  <div class="list-title-org">{{ item.Title }}</div>
                  <div class="flower"><img :src="flower" /></div>
                  <div class="list-text-org">
                    <div v-if="item.Contents" v-html="item.Contents"></div>
                    <div v-else>{{ translateTitle('敬请期待') }}</div>
                  </div>
                  <div class="flower"><img :src="flower" /></div>
                </div>
              </div>
            </el-col>
          </template>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  import { _GetArticlePageListBySID } from '@/utils/deal'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Organization',
    data() {
      return {
        list: [],
        title: '召集人组织',
        newskey: '8W',
        pageSize: 10,
      }
    },
    computed: {
      ...mapGetters({
        flower: ['settings/flowerUrl'],
        queryKeys: 'settings/queryKeys',
      }),
    },
    async created() {
      this.setKeys()
      await this.getlist()
    },
    async activated() {
      this.setKeys()
      // await this.getlist()
    },

    methods: {
      setKeys() {
        this.newskey = this.queryKeys.entrconf_organization_key
      },
      translateTitle,
      async getlist() {
        await this.querylist(1, this.pageSize)
      },
      async querylist(pageIndex, pageSize) {
        await _GetArticlePageListBySID(
          this.newskey,
          pageIndex,
          pageSize,
          (data) => {
            // console.log(data)
            if (data) {
              this.list = data.list
              // if (data.total == this.list.length) {
              //   this.isComplete = true
              //   this.showMore = false
              // }
            }
          },
          () => {},
          true
        )
      },
      async moreEvent(e) {
        if (this.isComplete) return
        this.currentPageIndex++
        console.log('moreEvent', e)
        await this.querylist(this.currentPageIndex, this.pageSize)
      },
      articleClick(e) {
        console.log('articleClick', e)
        if (e.OutLink) {
          window.open(e.OutLink)
        } else {
          this.$router.push({
            path: '/detail',
            query: {
              id: e.ArticleID,
              title: e.Title,
            },
          })
        }
      },
    },
  }
</script>
<style lang="scss">
  .list-item-org {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.12);
    padding: 5px 20px;
    .list-content-org {
      border-top: 2px solid #c78a29;
      margin: 20px;

      .list-title-org {
        padding: 20px 0px;
        font-weight: bold;
        // margin-bottom: 10px;
        font-size: 16px;
        // height: 42px;
      }
      .list-text-org {
        text-align: left;
        line-height: 22px;
        text-indent: 2em;
        // margin: 20px;
        // font-size: 14px;
        // height: 36px;
      }
    }
    .list-media-org {
      width: 100%;
      padding: 20px;
      height: 5rem;
      img {
        object-fit: contain;
        margin: 0 auto;
        width: 100%;
        max-height: 5rem;
        display: block;
      }
    }
  }
</style>
