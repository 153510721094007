import Vue from 'vue'
import store from '@/store'
import VueI18n from 'vue-i18n'
import elEn from 'element-ui/lib/locale/lang/en'
import elZh from 'element-ui/lib/locale/lang/zh-CN'
import elTh from 'element-ui/lib/locale/lang/th'
import en from './en'
import zh from './zh'
import th from './th'
import { getLocalLang } from '@/utils'
Vue.use(VueI18n)
const messages = {
  en: {
    ...en,
    ...elEn,
  },
  zh: {
    ...zh,
    ...elZh,
  },
  th: {
    ...th,
    ...elTh,
  },
}

function getLanguage() {
  const storelang = store.getters['settings/language']
  const language = getLocalLang()
  // console.log('getLanguage()', storelang, language)

  return storelang || language || 'zh'
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
})

export default i18n
