<template>
  <div class="index-container">
    <site-slogan />
    <div class="home-top">
      <div class="home-top-banners">
        <div class="swiper-out-wrapper-banner">
          <swiper-ete
            v-if="bannerlist && bannerlist.length"
            :swiper-list="bannerlist"
            wiper-name="banner"
          />

          <!-- <swiper-ete-multi-home
            v-if="bannerlist && bannerlist.length"
            :showt="false"
            :swiper-list="bannerlist"
          /> -->
        </div>
      </div>
      <div class="top-circle">
        <site-circle-menu @menuClick="menuIconClick" />
      </div>
    </div>
    <div class="home-content">
      <!-- <div class="home-section agenda-section">
        <div class="home-item">
          <tip-ete :icon="2" :title="'峰会议程'" />
          <el-tabs
            v-model="agendaTabActiveSID"
            class="agenda-tabs"
            @tab-click="handleTabClick"
          >
            <el-tab-pane
              v-for="(item, index) in agendaTabItems"
              :key="index"
              class="agenda-tabitem"
              :label="item.SName"
              :name="item.SID"
            >
              <template v-if="agendaList && agendaList.length">
                <agenda-list :list="agendaList" />
              </template>
              <template v-else>
                <div class="agenda-nodata center">敬请期待</div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div> -->
      <!-- <div class="home-section home-bg pdt20">
        <div class="home-item">
          <new-list
            v-if="newsList.length"
            :list="newsList"
            :stype="newskey"
            :title="'展会新闻'"
            @articleClick="articleClick"
            @moreEvent="moreEvent"
          />
        </div>
      </div> -->
      <div class="home-section video-section">
        <div class="home-item">
          <tip-ete
            :keyword="'video'"
            :more="true"
            :title="'视频'"
            @moreEvent="moreEvent"
          />
          <el-row class="video-item" :gutter="20">
            <el-col v-show="videoSelected" class="left" :md="20">
              <!-- <img :src="videoSelected.Address" /> -->
              <div class="video-box">
                <video-player
                  ref="videoPlayer"
                  class="video-player vjs-custom-skin"
                  :options="playerOptions"
                  :playsinline="true"
                />
              </div>
              <!-- <video
                controls
                loop
                :poster="videoUrl + '?x-oss-process=video/snapshot,t_2000'"
                :src="videoUrl"
                style="width: 100%"
                width="100%"
              ></video> -->
              <div class="title txt-one-line">
                <div class="txt">{{ videoSelected.Title }}</div>
              </div>
            </el-col>
            <el-col class="right" :md="4">
              <div class="video-item-list">
                <div
                  v-for="(item, index) in vedioList"
                  :key="index"
                  class="video-item-item"
                  @click="videoItemClick(item)"
                >
                  <div class="img-hover">
                    <img :src="item.OssImagePath + item.LogoPath" />
                  </div>
                  <div class="title txt-two-line">{{ item.Title }}</div>
                  <div class="txt txt-two-line">{{ item.Abstract }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="home-section news-section">
        <div class="home-item">
          <tip-ete
            :keyword="'news'"
            :more="true"
            :title="'大会最新新闻'"
            @moreEvent="moreEvent"
          />
          <el-row class="news-item" :gutter="20">
            <el-col class="left" :md="12">
              <img
                v-if="newsModel.LogoPath"
                :src="newsModel.OssImagePath + newsModel.LogoPath"
              />
            </el-col>
            <el-col class="right" :md="12">
              <div class="title">{{ newsModel.Title }}</div>
              <div
                v-if="newsModel.Contents"
                class="newtext"
                v-html="newsModel.Contents"
              ></div>
            </el-col>
          </el-row>
          <!-- <div class="news-item">
            <div class="left">
              <img :src="newsModel.OssImagePath + newsModel.LogoPath" />
            </div>
            <div class="right">
              <div class="title">{{ newsModel.Title }}</div>
              <div v-if="newsModel.Contents" v-html="newsModel.Contents"></div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="home-section agenda-section">
        <div class="home-item">
          <tip-ete
            :date="`${translateTitle('日期头')}${AgendaSubject.Abstract || ''}`"
            keyword="agenda"
            :more="true"
            :subtitle="`${translateTitle('主会场头')}${
              AgendaSubject.Title || ''
            }`"
            :title="'大会日程'"
            @moreEvent="moreEvent"
          />
          <template v-if="agendaList && agendaList.length">
            <agenda-list :list="agendaList" />
          </template>
          <template v-else>
            <div class="agenda-nodata center">
              {{ translateTitle('敬请期待') }}
            </div>
          </template>
        </div>
      </div>
      <div class="home-section home-ep">
        <div class="home-item">
          <tip-ete v-if="epList && epList.length" :title="'大会赞助企业'" />
          <div class="swiper-out-wrapper">
            <swiper-ete-multi :showt="false" :swiper-list="epList" />
            <!-- <swiper-ete
              v-if="epList && epList.length"
              bullet="bottom"
              :swiper-list="epList"
              wiper-name="zsqy"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <site-notice ref="dialogRef">
      <img v-if="noticeImage" :src="noticeImage" width="100%" />
    </site-notice>
  </div>
</template>
<script>
  // import videoPlayer from 'vue-video-player'
  // import 'vue-video-player/src/custom-theme.css'
  // import 'video.js/dist/video-js.css'
  import { mapGetters } from 'vuex'
  import { rdv } from '@/utils/index'
  import { getMenuTreeByType } from '@/api/site'
  import { exhcenterUrl, exhApplyUrl } from '@/config'
  import { translateTitle } from '@/utils/i18n'
  import {
    _GetArticleByType,
    _GetArticleListByType,
    _GetTags,
    // _GetArticlePageListByType,
    GetFinalAgendaList,
    _GetNextArticleListBySID,
    _GetArticleListBySID,
  } from '@/utils/deal'
  export default {
    name: 'Index',
    filters: {
      rdv(url) {
        return rdv(url)
      },
    },
    data() {
      return {
        playerOptions: {
          aspectRatio: '16:9',
          autoplay: false,
          muted: false, // 默认情况下将会消除任何音频。
          language: 'zh-CN',
          loop: true, // 是否视频一结束就重新开始。
          volume: 0.3,
          playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
          // fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [],
          //   {
          //     type: 'video/mp4',
          //     src: 'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/videos/video16.mp4',
          //   },
          // ],
          poster: '',
          //   'https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/videos/video16.mp4?x-oss-process=video/snapshot,t_2000',
          // controlBar: {
          //   // volumeControl: false,
          //   // timeDivider: false,
          //   // durationDisplay: false,
          //   // remainingTimeDisplay: true,
          //   // fullscreenToggle: true, // 全屏按钮
          // },
        },
        regIcon: 'regicon.png',
        appqrIcon: 'appqr.png',
        exhIcon: 'exhicon.png',
        applyIcon: 'apply.png',
        bannerlist: [],
        vedioList: [],
        videoSelected: '',
        aboutus: {
          list: [],
          title: '',
          content: '',
          logo: '',
          ArticleID: '',
        },
        newsModel: '',
        zxfbList: [],
        epList: [],
        agendaList: [],
        AgendaSubject: '',
        agendaTabItems: [],
        agendaTabActiveSID: '',
        newskey: '',
        epkey: '',
        agendaKey: '',
        homebannerkey: '',
        videoSID: '',
        noticeImage: '',
        // newskey: 'homeNew',
        // epkey: '7X',
        // agendaKey: 'homeDhrc',
        // homebannerkey: 'HomeBanners',
        // videoSID: 'BM',
      }
    },
    computed: {
      ...mapGetters({
        homeTopUrl: 'settings/homeTopUrl',
        ossBaseUrl: 'settings/ossBaseUrl',
        queryKeys: 'settings/queryKeys',
        videoUrl: 'settings/videoUrl',
      }),
    },
    async created() {
      this.setKeys()
      await this.getBanners()
      await this.getNews()
      await this.getAgendaList()
      await this.getVideoList()
      await this.getEnterpriseList()
      await this.getNotice()
    },
    async activated() {
      this.setKeys()
      console.log('activated')
    },
    methods: {
      // onPlayerPlay(player) {
      //   console.log('播放', player)
      // },
      async getNotice() {
        await _GetArticleByType(this.appnoticekey, (data) => {
          if (data && data.OssImagePath && data.LogoPath) {
            this.noticeImage = data.OssImagePath + data.LogoPath
            this.$refs.dialogRef.showDialog()
          }
        })
      },
      setVideo() {
        // console.log('setVideo:', this.videoSelected)
        const videoAddress = this.videoSelected.Address || this.videoUrl
        const videoPoster =
          videoAddress + '?x-oss-process=video/snapshot,t_2000'

        this.playerOptions.sources = [
          {
            type: 'video/mp4',
            src: videoAddress,
          },
        ]
        this.playerOptions.poster = videoPoster
        this.$nextTick(() => {
          if (this.$refs.videoPlayer) this.$refs.videoPlayer.player.volume(0.5)
        })
      },
      setKeys() {
        const $this = this
        if ($this.queryKeys) {
          Object.keys($this.queryKeys).forEach((key) => {
            $this[key] = $this.queryKeys[key]
          })
        }
      },
      translateTitle,
      menuClick(type) {
        switch (type) {
          case 'reg':
            this.$baseNotify('已截止')
            // window.open(registerUrl)
            break
          case 'exh':
            window.open(exhcenterUrl)
            break
          case 'apply':
            window.open(exhApplyUrl)
            break
        }
      },
      async getVideoList() {
        await _GetArticleListBySID(this.videoSID, (list) => {
          // let imageArray = []
          // if (list) {
          //   list.forEach((item) => {
          //     if (item.ImageUrl) imageArray.push({ src: item.ImageUrl })
          //   })
          // }
          this.vedioList = list
          this.videoSelected = list[0]
          // console.log('getVideoList', list)
        })
        this.setVideo()
      },
      async getBanners() {
        await _GetTags(this.homebannerkey, (list) => {
          let imageArray = []
          if (list) {
            list.forEach((item) => {
              if (item.ImageUrl) imageArray.push({ src: item.ImageUrl })
            })
          }
          this.bannerlist = imageArray
        })
      },
      async getEnterpriseList() {
        // await _GetTags(this.epkey, (list) => {
        //   var testlist = []
        //   for (var i = 0; i < 20; i++) {
        //     testlist = testlist.concat(list)
        //   }
        //   this.epList = testlist
        // })
        await _GetArticleListBySID(this.epkey, (list) => {
          var temp = []
          if (list) {
            list.forEach((item) => {
              temp.push({ src: item.OssImagePath + item.LogoPath })
            })
          }
          this.epList = temp
        })
      },
      async getNews() {
        await _GetArticleByType(this.newskey, (data) => {
          if (data) {
            this.newsModel = data
          }
        })
      },

      async getAgendaMenu() {
        const res = await getMenuTreeByType({ SType: this.agendaKey })
        if (res && res.data) {
          const menus = res.data.length > 0 ? res.data[0].Children : null
          if (menus) {
            const tabitems = []
            let firstsid = ''
            menus.forEach((item, index) => {
              console.log(index)
              if (index == 0) firstsid = item.SID
              tabitems.push({ SName: item.SName, SID: item.SID })
            })
            this.agendaTabItems = tabitems
            this.agendaTabActiveSID = firstsid
            if (this.agendaTabActiveSID) {
              this.handleTabClick()
            }
          }
        }
      },
      async handleTabClick() {
        if (this.agendaTabActiveSID) {
          await _GetNextArticleListBySID(this.agendaTabActiveSID, (list) => {
            this.setAgendaList(list)
          })
        }
      },
      setAgendaList(list) {
        if (list) {
          // let sortList = DealAgendaData(list)
          // console.log('sortList', sortList)
          // // sortList.splice(1, 0, ...sortList.splice(2, 1))
          // let newGroupList = []
          // let totalCount = sortList.length
          // let totalIndex = 0
          // if (totalCount > 0) {
          //   if (totalCount <= 2) totalIndex = 1
          //   else if (totalCount / 2 == 0) totalIndex = totalCount / 2
          //   else totalIndex = totalCount / 2 + 1
          // }

          // for (let i = 1; i <= totalCount; i++) {
          //   newGroupList.push({
          //     rowid: i,
          //     list: sortList.splice(0, totalIndex),
          //   })
          // }
          // console.log('newGroupList', newGroupList)
          // newGroupList.forEach((o1) => {
          //   o1.list.forEach((o2) => {
          //     let tempList = []
          //     o2.list.forEach((o3) => {
          //       let exist = tempList.find(
          //         (i) =>
          //           i.ReleaseTimeText == o3.ReleaseTimeText &&
          //           o3.ReleaseTimeText
          //       )
          //       if (exist) {
          //         exist.list.push(o3)
          //       } else {
          //         tempList.push({
          //           ReleaseTimeText: o3.ReleaseTimeText,
          //           list: [o3],
          //         })
          //       }
          //     })
          //     // console.log('tempList', tempList)
          //     o2['newlist'] = tempList
          //   })
          // })
          this.agendaList = GetFinalAgendaList(list)
        }
      },
      async getAgendaList() {
        await _GetArticleListByType(this.agendaKey, (list) => {
          // console.log(list)
          const menuArtList = list.filter((f) => f.ContentType == 'MenuArticle')
          // console.log('getAgendaList-menuarticle', menuArtList)
          this.AgendaSubject = menuArtList[0]
          this.setAgendaList(list)
          // console.log('this.agendaList', this.agendaList)
          // if (list) {
          //   let sortList = DealAgendaData(list)
          //   console.log(sortList)
          //   // sortList.splice(1, 0, ...sortList.splice(2, 1))
          //   let newGroupList = []
          //   let totalCount = sortList.length
          //   let totalIndex = 0
          //   if (totalCount > 0) {
          //     if (totalCount <= 2) totalIndex = 1
          //     else if (totalCount / 2 == 0) totalIndex = totalCount / 2
          //     else totalIndex = totalCount / 2 + 1
          //   }
          //   for (let i = 1; i <= totalIndex; i++) {
          //     newGroupList.push({ rowid: i, list: sortList.splice(0, 2) })
          //   }
          //   console.log('newGroupList', newGroupList)
          //   newGroupList.forEach((o1) => {
          //     o1.list.forEach((o2) => {
          //       let tempList = []
          //       o2.list.forEach((o3) => {
          //         let exist = tempList.find(
          //           (i) =>
          //             i.ReleaseTimeText == o3.ReleaseTimeText &&
          //             o3.ReleaseTimeText
          //         )
          //         if (exist) {
          //           exist.list.push(o3)
          //         } else {
          //           tempList.push({
          //             ReleaseTimeText: o3.ReleaseTimeText,
          //             list: [o3],
          //           })
          //         }
          //       })
          //       console.log('tempList', tempList)
          //       o2['newlist'] = tempList
          //     })
          //   })
          //   this.agendaList = newGroupList
          //   console.log('this.agendaList', this.agendaList)
          // }
        })
      },
      moreEvent(e) {
        console.log('moreEvent', e)
        // this.$router.push({
        //   path: 'news',
        // })
        if (e && e.keyword === 'news') {
          this.$router.push({
            path: 'newCenter/wnews',
          })
        } else if (e && e.keyword === 'video') {
          this.$router.push({
            path: 'newCenter/atlas/newvideos',
          })
        }
      },
      toMore() {
        // this.$navigateTo(
        //   `/pages/articleList/articleList?type=${this.showType}&menuid=${this.menuid}&title=${e.C_TITLE}`
        // )
      },
      menuIconClick(queryParams) {
        console.log('menuIconClick(queryParams)', queryParams)
        if (queryParams && queryParams.menukey) {
          this.$router.push({
            path: 'menuDetail',
            query: queryParams,
          })
        }
      },
      metomore() {
        // this.$navigateTo(
        //   `/pages/articleDetail/articleDetail?isMultImage=1&id=${this.aboutus.ArticleID}&title=${this.aboutus.title}`
        // )
        this.$router.push({
          path: 'detail',
          query: {
            isMultImage: 1,
            sd: 1,
            id: this.aboutus.ArticleID,
            title: this.aboutus.title,
          },
        })
      },
      videoItemClick(e) {
        if (e) this.videoSelected = e
        this.setVideo()
      },
      articleClick(e) {
        console.log('articleClick', e)
        if (e.OutLink) {
          window.open(e.OutLink)
        } else {
          this.$router.push({
            path: 'detail',
            query: {
              id: e.ArticleID,
              title: e.Title,
            },
          })
        }
      },
    },
  }
</script>
<style lang="scss">
  .video-box {
    width: 100%;
    height: 100%;
    .vjs-custom-skin > .video-js .vjs-big-play-button {
      background-color: rgba(0, 0, 0, 0.45);
      font-size: 3.5em;
      border-radius: 50%;
      height: 2em !important;
      line-height: 2em !important;
      margin-top: -1em !important;
      margin-left: -1em !important;
      width: 2em !important;
      outline: none;
      border: none;
      .vjs-icon-placeholder::before {
        font-size: 3rem;
      }
    }
  }
  .swiper-out-wrapper {
    // background-image: url('https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/test.JPG');
    // &::before {
    //   content: '';
    //   width: 100%;
    //   height: 100%;
    //   backdrop-filter: blur(40px);
    //   position: absolute;
    // }
  }
  .video-item-list {
    .video-item-item {
      margin-top: 10px;
      text-align: left;
      color: #333333;
      border: 1px solid #ebebeb;
      padding: 10px;
      cursor: pointer;
      &:first-child {
        margin-top: 0px;
      }
      img {
        width: 100%;
      }
      .title {
        font-weight: bold;
        margin: 10px 0px;
      }
      .txt {
        color: #666666;
      }
    }
  }

  // .index-container .home-top .swiper-out-wrapper .swiper-slide {
  //   img {
  //     // border-radius: 0 0 50% 50%/15%;
  //   }
  // }
  .news-item {
    // display: flex;
    // align-items: center;

    // justify-content: space-between;
    padding-top: 30px;
    .left {
      // width: 44%;

      img {
        width: 100%;
        border-radius: 8px;
        object-fit: contain;
        // height: calc(80vw * 700px / 1600px);
        // border: 1px solid #c78a29;
      }
    }
    .right {
      // width: 52%;
      color: #2d2d2d;
      text-align: left;
      height: calc(80vw * 583px / 1600px);
      overflow: auto;
      .newtext {
        letter-spacing: 3px;
      }
      .title {
        margin-bottom: 20px;
        font-size: $font20;
        font-weight: bold;
      }
    }
  }
  .video-item {
    background: #fff;
    padding: 15px 5px;
    margin-top: 15px;
    min-height: 400px;
    .left {
      // margin-right: 50px;
      // width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .title {
        text-align: left;
        // position: absolute;
        // bottom: -45px;
        // left: 10px;
        // right: 10px;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: rgba(28, 28, 30, 1);
        .txt {
          color: #fff;
          padding-left: 10px;
        }
      }
      img {
        width: 100%;
      }
    }
    .right {
      height: calc(90vw * 800px / 1600px);
      overflow: auto;
    }
  }
  // .seawave {
  //   margin-top: -150px;
  //   position: relative;
  //   z-index: 10;
  // }
  .clear-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .index-container {
    .home-top {
      margin-top: -50px;
      .home-top-banners {
        // min-height: 600px;
        // background-image: url(https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/homebgagn.jpg) !important;
        // box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.5);
        // background: linear-gradient(
        //   90deg,
        //   #8a6a43 0%,
        //   #a38d76 29%,
        //   #b8a9a2 64%,
        //   #afa196 69%,
        //   #958169 77%,
        //   #8a6a43 100%
        // );
        // max-height: 650px;
        position: relative;
        top: 0px;
        .swiper-out-wrapper-banner {
          overflow: hidden;
          margin: auto;
          max-height: 800px;
          .swiper-slide {
            // height: 650px;
            img {
              border-radius: 0 0 50% 50%/5%;
              width: 100%;
              object-fit: cover;
              max-height: 800px;
            }
          }
        }
      }
      .top-circle {
        // width: 100%;
        // position: relative;
        // top: -80px;
        // z-index: 11;
        // margin-bottom: calc(-100vw * 150px / 1000px);
      }
    }

    .home-content {
      position: relative;
      .home-menu {
        width: 100%;
        .menu-list {
          margin: auto;
          width: 90%;
          .menu-item {
            text-align: center;
            margin-top: 20px;
            > img {
              height: 180px;
              width: 180px;
            }
            .title {
              font-size: 20px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .home-section {
    padding-top: 30px;
    .home-show,
    .home-item {
      width: 90%;
      margin: 0 auto;
      .home-show-right {
        margin-top: 15px;
      }
      .yc-wrapper {
        padding: 20px 0px;
      }
      .des {
        text-align: left;
        .des-txt {
          line-height: 1.5;
        }
      }
    }
  }
  .home-ep {
    background-image: url('https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/homebjwhite1.jpg');
    padding-bottom: 30px;
    .swiper-out-wrapper {
      padding: 20px 0px;
    }
  }
  .news-section {
    background-image: url('https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/homebjwhite1.jpg');
    padding: 20px 0px;
  }
  .video-section {
    padding: 20px 0px;
  }
  .home-bg {
    background-color: $base-color-background;
  }
</style>
