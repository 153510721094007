<template>
  <div class="sea-wave-com">
    <svg
      class="waves"
      preserveAspectRatio="none"
      shape-rendering="auto"
      viewBox="0 24 150 28"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g class="parallax">
        <use
          fill="rgba(255,255,255,0.7)"
          x="28"
          xlink:href="#gentle-wave"
          y="0"
        />
        <use
          fill="rgba(255,255,255,0.5)"
          x="38"
          xlink:href="#gentle-wave"
          y="3"
        />
        <use
          fill="rgba(255,255,255,0.3)"
          x="48"
          xlink:href="#gentle-wave"
          y="5"
        />
        <use
          fill="rgba(255,255,255,1)"
          x="18"
          xlink:href="#gentle-wave"
          y="7"
        />
      </g>
    </svg>
  </div>
</template>
<script>
  export default {
    name: 'SeaWave',
  }
</script>
<style>
  @-webkit-keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }

    100% {
      transform: translate3d(85px, 0, 0);
    }
  }

  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }

    100% {
      transform: translate3d(85px, 0, 0);
    }
  }

  @-webkit-keyframes move-up-down {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-15px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  @keyframes move-up-down {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .sea-wave-com {
    background: none;
    width: 100%;
    height: 150px;
    position: relative;
  }

  .waves {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    /* height: 15vh; */
    min-height: 100px;
    max-height: 150px;
  }

  .waves .content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white;
  }

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }

  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }

  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }

  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }

  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
</style>
