<template>
  <div
    class="site-header clearfix"
    :class="{ 'mobile-menu-header': showMobileMenu }"
  >
    <section>
      <div class="header-menu">
        <div class="mobile-menu-icon" @click="showMenu()">
          <i class="el-icon-s-operation"></i>
        </div>
        <div class="header-logo dh">
          <!-- <img v-if="banner" :src="banner | rdv" width="389" /> -->
          <a
            href="https://www.wcecofficial.org/cn/index.php/event"
            target="_blank"
          >
            <img :src="wceclogo" width="190" />
          </a>
          <a href="https://www.thaicc.org/home">
            <img :src="thceclogo" target="_blank" width="190" />
          </a>
        </div>
        <!-- <el-menu
          v-if="'horizontal' === layout"
          :active-text-color="variables['menu-color-active']"
          :background-color="variables['menu-background']"
          :default-active="activeMenu"
          menu-trigger="hover"
          mode="horizontal"
          :text-color="variables['menu-color']"
        >
          <template v-for="(route, index) in handleRoutes">
            <site-menu
              v-if="route.meta && !route.meta.hidden"
              :key="index + route.name"
              :item="route"
              :layout="layout"
            />
          </template>
        </el-menu> -->
        <div class="menu-list">
          <!-- <el-menu
            :active-text-color="variables['menu-color-active']"
            :default-active="activeMenu"
            menu-trigger="hover"
            mode="horizontal"
            :text-color="variables['menu-color']"
          > -->
          <el-menu
            :active-text-color="variables['menu-color-active']"
            :default-active="activeMenu"
            menu-trigger="hover"
            mode="horizontal"
            :text-color="variables['menu-color']"
          >
            <template v-for="(route, index) in handleRoutes">
              <site-menu
                v-if="route.meta && !route.meta.hidden"
                :key="index + route.name"
                :item="route"
                :layout="layout"
              />
            </template>
          </el-menu>
        </div>
        <div class="header-btn-tool">
          <el-dropdown @command="changeLang">
            <span class="el-dropdown-link">
              {{ selectLang }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="langclass">
              <el-dropdown-item command="zh">中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
              <el-dropdown-item command="th">ภาษาไทย</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <ul class="menu-list">
          <template v-for="(route, index) in handleRoutes">
            <li
              v-if="route.meta && !route.meta.hidden"
              :key="index + route.name"
              class="menu-item"
              :class="{ active: activeMenu == route.path }"
            >
              <a @click="routeClick(route)">
                {{ translateTitle(route.meta.title) }}
              </a>
            </li>
          </template>
        </ul> -->
      </div>
      <!-- <div class="header-btn-tool">
        <el-dropdown @command="changeLang">
          <span class="el-dropdown-link">
            {{ selectLang }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
            <el-dropdown-item command="th">ภาษาไทย</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
    </section>

    <div class="mobile-menu">
      <div class="menu-layer"></div>
      <nav class="menu-box">
        <div class="menu-close">
          <div class="close" @click="closemenu()">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="menu-list">
          <el-menu
            :active-text-color="variables['menu-color-active']"
            :default-active="activeMenu"
            menu-trigger="hover"
            :text-color="variables['menu-color']"
            @select="handleSelect"
          >
            <template v-for="(route, index) in handleRoutes">
              <site-menu
                v-if="route.meta && !route.meta.hidden"
                :key="index + route.name"
                :item="route"
                :layout="layout"
              />
            </template>
          </el-menu>
        </div>
        <!-- <ul class="menu-list">
          <template v-for="(route, index) in handleRoutes">
            <li
              v-if="route.meta && !route.meta.hidden"
              :key="index + route.name"
              class="menu-item"
              :class="{ active: activeMenu == route.path }"
            >
              <a @click="routeClick(route)">{{ route.meta.title }}</a>
            </li>
          </template>
        </ul> -->
        <!-- <div class="content">
          <div class="btn-reg" @click="toReg()">参会/参观注册</div>
          <div class="contact-us">
            <h3>联系方式</h3>
            <div class="txt">010-5338 7586</div>
            <div class="txt">topex@topexpo.cc</div>
          </div>
        </div> -->
      </nav>
    </div>
  </div>
</template>
<script>
  import variables from '@/common/styles/variables.scss'
  import { mapActions, mapGetters } from 'vuex'
  import { handleActivePath, handleMatched, handleTabs } from '@/utils/routes'
  import { rdv } from '@/utils/index'
  import getPageTitle from '@/utils/pageTitle'
  import { getMainMenu } from '@/api/site'
  import { /*registerUrl,*/ routerMode } from '@/config'
  import { isExternal } from '@/utils/validate'
  import { translateTitle } from '@/utils/i18n'
  import { getLocalLang } from '@/utils'
  export default {
    name: 'SiteHeader',
    filters: {
      rdv(url) {
        return rdv(url)
      },
    },
    data() {
      return {
        layout: 'horizontal',
        tabActive: '',
        activeMenu: 'index',
        showMobileMenu: false,
        selectLang: '中文',
        menuList: [],
      }
    },
    computed: {
      ...mapGetters({
        banner: 'settings/banner',
        thceclogo: 'settings/thceclogo',
        wceclogo: 'settings/wceclogo',
        routes: 'routes/routes',
        activeName: 'routes/activeName',
      }),
      variables() {
        return variables
      },
      handleRoutes() {
        return this.routes.flatMap((route) => {
          // console.log('handleRoutes', route)
          return route.meta && route.meta.levelHidden === true && route.children
            ? route.children
            : route
        })
      },
    },
    watch: {
      $route: {
        handler(route) {
          this.activeMenu = handleActivePath(route)
          console.log('warch-route-activeMenu', this.activeMenu)
          this.$nextTick(() => {
            this.addTabs(route)
          })
        },
        immediate: true,
      },
      activeName: {
        handler(val) {
          // console.log('warch-activeName', val)
          const matched = handleMatched(this.routes, val)
          // console.log('warch-activeName-matched', matched)
          this.activeMenu = matched[matched.length - 1].path
        },
      },
    },
    created() {
      // this.getMenuList()
      this.setSelectLang(getLocalLang())
    },
    methods: {
      ...mapActions({
        addVisitedRoute: 'tabs/addVisitedRoute',
        changeLanguage: 'settings/changeLanguage',
      }),
      translateTitle,
      handleSelect(key, keyPath) {
        console.log('handleSelect:', key, keyPath)
        this.showMobileMenu = false
      },
      setSelectLang(lang) {
        this.selectLang =
          lang == 'en' ? 'English' : lang == 'th' ? 'ภาษาไทย' : '中文'
      },
      changeLang(command) {
        // console.log('command', command)
        this.setSelectLang(command)
        this.changeLanguage(command)
        //切换语言
        this.$i18n.locale = command
        document.title = getPageTitle(this.$route.meta.title)
        //重置接口地址
        //重新加载路由
        // console.log('this.reload', this.reload)
        this.$baseEventBus.$emit('reload-router-view')
      },
      async addTabs(tag, init = false) {
        const tab = handleTabs(tag, init)
        if (tab) {
          await this.addVisitedRoute(tab)
          this.tabActive = tab.path
        }
      },
      closemenu() {
        this.showMobileMenu = false
      },
      showMenu() {
        this.showMobileMenu = !this.showMobileMenu
      },
      routeClick(item) {
        console.log('routeClick', item)
        this.showMobileMenu = false
        const routePath = item.path
        const target = item.meta.target

        if (target === '_blank') {
          if (isExternal(routePath)) window.open(routePath)
          else if (this.$route.fullPath !== routePath)
            routerMode === 'hash'
              ? window.open('/#' + routePath)
              : window.open(routePath)
        } else {
          if (isExternal(routePath)) window.location.href = routePath
          else if (this.$route.fullPath !== routePath) {
            this.$router.push(item.path)
          } else this.$baseEventBus.$emit('reload-router-view')
        }
      },
      menuClick(item) {
        console.log(item)
        if (item) {
          switch (item.SNType) {
            case 'ilink':
              if (item.link) {
                this.$router.push({
                  path: item.link,
                })
              }
              break
            case 'article':
              this.$router.push({
                path: 'detail',
              })
              break
            default:
              break
          }
        }
      },
      toReg() {
        this.$baseNotify('已截止')
        // window.open(registerUrl)
      },
      // tranferLang(lang) {
      //   console.log(lang)
      //   this.$baseMessage('敬请期待', 'warning')
      // },
      async getMenuList() {
        const res = await getMainMenu()
        if (res && res.data) {
          this.menuList = res.data
        }
      },
    },
  }
</script>
<style lang="scss">
  .langclass {
    .el-dropdown-menu__item:focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
      background-color: #fff;
      color: $base-color-red !important;
    }
  }

  .el-menu {
    white-space: nowrap;
  }
  .el-menu--horizontal > .el-menu-item {
    display: inline !important;
  }
  .el-menu--horizontal > .el-submenu {
    display: inline !important;
  }
  .mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 2000;
    .menu-layer {
      position: fixed;
      background: rgba(0, 0, 0, 0.7);

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.7s ease;
      transform: translateX(-100%);
    }

    .menu-box {
      position: absolute;
      left: -0px;
      top: 0px;
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
      transform: translateX(-100%);
      background: $base-color-bg;
      background-image: url(https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png) !important;
      // background: rgba(204, 153, 53, 1);
      .el-menu {
        background: $base-color-bg;
        background-image: url(https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png) !important;
      }
      .el-submenu__title:hover {
        color: $base-color-red !important;
        background: none !important;
      }
      .el-menu-item:focus,
      .el-menu-item:hover {
        color: $base-color-red !important;
        background: none !important;
      }

      .menu-close {
        height: 50px;
        position: relative;
        .close {
          // margin: 10px 10px 0px 0px;
          text-align: right;
          font-size: 24px;
          width: 24px;
          color: #000;
          cursor: pointer;
          transition: all 0.9s ease;
          position: absolute;
          top: 10px;
          right: 10px;
          &:hover {
            transform: rotate(360deg);
          }
        }
      }
      .menu-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
        .menu-item {
          position: relative;
          display: block;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          a {
            color: #fff;
            display: block;
            padding: 10px 25px;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
      .content {
        padding: 10px 25px;
        .contact-us {
          color: #fff;
          .txt {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .mobile-menu-header {
    .mobile-menu {
      opacity: 1;
      visibility: visible;
      .menu-layer {
        transform: translateX(0%);
      }
      .menu-box {
        opacity: 1;
        visibility: visible;
        transform: translateX(0%);
      }
    }
  }
  .site-header {
    position: relative;
    text-align: left;
    padding: 10px;
    // height: 70px;
    // background-color: $base-color-white;
    // background-color: none;
    background-image: url(https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04); //最先写的阴影将显示在最顶层
    z-index: 100;
    width: 100%;

    .header-menu {
      // float: left;
      margin-left: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mobile-menu-icon {
        font-size: 40px;
        display: none;
        margin-left: 10px;
        // float: left;
      }
      .header-logo {
        // float: left;
        // display: block;
        // padding-right: 10px;
        display: flex;
      }
      .menu-list {
        .el-menu-item {
          height: 30px !important;
          line-height: 30px !important;
        }
        .el-menu--horizontal > .el-submenu .el-submenu__title {
          height: 30px !important;
          line-height: 30px !important;
        }
        // display: inline-block;
        // list-style: none;
        padding: 0;
        margin: 0;
        // max-width: 500px;
        // overflow: auto;
        // height: 60px;
        .menu-item {
          // padding: 5px 20px;
          // margin-left: 5px;
          // float: left;
          // margin-top: 15px;
          &:first-child {
            margin-left: 5px;
          }
          a {
            // transition: $base-transition-all;
            font-weight: 500;
            letter-spacing: 1px;
          }
          &:hover {
            a {
              // font-size: 16px;
              // transform: scale(1.1);
              // display: block;
              color: #ca3935;
              font-weight: 600;
            }
          }
          &.active {
            background: #ca3935;
            border-radius: 0px 14px 0px 14px;
            a {
              color: #fff;
            }
          }
        }
      }
      .header-btn-tool {
        padding: 5px 10px;
        width: 101px;
        flex-shrink: 0;
      }
    }
    .btn-reg-wcec {
      background: #ca3935;
      border-radius: 0px 14px 0px 14px;
      padding: 5px 10px;
      color: #fff;
    }
    .btn-reg,
    .btn-tool-reg {
      cursor: pointer;
      color: #fff;
      padding: 8px 10px;
      background: linear-gradient(90deg, #ca3935 0%, #bb08ab 100%);
      border-radius: 4px;
      border: 1px solid #bb08ab;
      display: block;
      text-align: center;
    }
    .btn-reg {
      display: inline-block;
    }

    .el-menu-item,
    .el-submenu__title {
      padding: 0px 10px;
    }
    .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
      margin-left: 0px;
      margin-top: 0px;
    }
    // .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
    // .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    //   outline: none;
    //   color: #c41230 !important;
    // }
    // .el-menu--horizontal > .el-submenu:hover .el-submenu__title {
    //   color: #c41230 !important;
    // }
  }
</style>
