import request from '@/utils/request'

export async function getArticle(data) {
  return request({
    url: 'article',
    method: 'post',
    data,
  })
}
export async function getMenuArticle(data) {
  return request({
    url: 'article/sid',
    method: 'post',
    data,
  })
}
export async function getArticleList(data) {
  return request({
    url: 'articles',
    method: 'post',
    data,
  })
}
export async function getArticlePageList(data) {
  return request({
    url: 'articlespage',
    method: 'post',
    data,
  })
}
export async function getNextArticleList(data) {
  return request({
    url: 'articles/next',
    method: 'post',
    data,
  })
}
export async function getNextArticlePageList(data) {
  return request({
    url: 'articlespage/next',
    method: 'post',
    data,
  })
}
export async function getArticleByType(data) {
  return request({
    url: 'article/type',
    method: 'post',
    data,
  })
}
export async function getArticleListByType(data) {
  return request({
    url: 'articles/type',
    method: 'post',
    data,
  })
}
export async function getArticlePageListByType(data) {
  return request({
    url: 'articlespage/type',
    method: 'post',
    data,
  })
}
export async function getMenu(data) {
  return request({
    url: 'subjecttree',
    method: 'post',
    data,
  })
}
export async function getMainMenu(data) {
  return request({
    url: 'subjecttree/main',
    method: 'post',
    data,
  })
}
export async function getMenuListByPId(data) {
  return request({
    url: 'subjects/pid',
    method: 'post',
    data,
  })
}
export async function getMenuTreeByPId(data) {
  return request({
    url: 'subjecttree/pid',
    method: 'post',
    data,
  })
}
export async function getMenuTreeByType(data) {
  return request({
    url: 'subjecttree/type',
    method: 'post',
    data,
  })
}
export async function tagList(data) {
  return request({
    url: 'tag/list',
    method: 'post',
    data,
  })
}
export async function tagFiles(data) {
  return request({
    url: 'tag/files',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/userInfo',
    method: 'get',
  })
}
export function logout() {
  return request({
    url: '/logout',
    method: 'get',
  })
}
